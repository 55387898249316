import { Hidden } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {
    // background: "#911717",
    // marginTop: "-60px!important"
  },
  grid: {
    padding: 40,
    paddingBottom:100
  },
  card: {
    maxWidth: 310,
    padding: theme.spacing(2),
  },

  formControl: {
    margin: theme.spacing(1),
    minWidth: 160,
    borderColor: "white",
    paddingRight: "50px"
  },

  icon: {
    fill: 'white',
  },

  options: {
    color: "black!important"
  },
  wrapIcon: {
    display: 'flex',
    justifyContent: 'space-between',

  },
  storeIcon: {
    backgroundColor: "#911717",
    borderRadius: '50%',
    height: 45,
    width: 45,
    '&:hover': {
      backgroundColor: '#911717',
      cursor: 'default'
    },

    border: "none",

  },
  content: {
    height: 200,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1
  },
  navbar: {
    backgroundColor: "transparent",
    boxShadow: "none"
  },
  content: {
    height: 200,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1
  },
  navbar: {
    backgroundColor: "transparent",
    boxShadow: "none"
  },
  content: {
    height: 200,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1
  },
  navbar: {
    backgroundColor: "transparent",
    boxShadow: "none"
  },
  go_btn: {
    '&:hover': {
      color: "#fff",
      borderColor: "#a02929",
      backgroundColor: "#a02929"
    },
    width: 100,
    fontWeight: "bold",
    float: "right",
    color: "#a02929",
    borderColor: "#a02929"
  },
  '.MuiCardActionArea-root': {
    backgroundColor: "#222"
  }
}))