import React, { useEffect } from 'react'
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import MenuItem from '@material-ui/core/MenuItem';
import { Loading } from '../Loading'
import Autocomplete from '@material-ui/lab/Autocomplete';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '25ch'
    },
  }
}));

export default function EditExaminers(props) {
  const classes = useStyles();
  const [subjectOpts, setSubjectOpts] = React.useState([])
  // const [defaultSubjects,setDefaultSubjects] = React.useState()
  const [autoValue, setAutoValue] = React.useState([])

  const options = props.options
  useEffect(() => {

    let subs = []
    props.options.info.subjectData.forEach(subject => {
      subs.push(subject.name)
    })

    props.options.setInfo({ ...props.options.info, "subjectData": subs })

    setAutoValue(subs)
    const filteredArray = props.options.filteredSubjects.filter(element => !subs.includes(element))
    setSubjectOpts(filteredArray)

  }, [])

  useEffect(() => {
    if (autoValue) {
      const filteredArray = options.filteredSubjects.filter(element => !autoValue.includes(element))
      setSubjectOpts(filteredArray)
    }
  }, [props.options.filteredSubjects, autoValue])

  // function handleChangeGender(e){
  //   options.setGender(e.target.value)
  // }
  function handleChangeExaminerType(e) {
    options.setExaminerType(e.target.value)
  }

  function handleChangeDepartment(e) {
    setAutoValue([])
    options.setDept(e.target.value)
  }

  function handleSubjectInput(e, val) {
    options.setInfo({ ...options.info, "subjectData": val })
    setAutoValue(val)
    const filteredArray = options.filteredSubjects.filter(element => !val.includes(element))
    setSubjectOpts(filteredArray)
  }

  function handleInput(e) {
    options.setErrMsg({ ...options.errMsg, allFieldsFilled: "" })
    let name = e.target.name
    options.setInfo({ ...options.info, [name]: e.target.value })
  }
  if (autoValue) {
    return (
      <form className={classes.root}>

        <div align='center' className={classes.error}>{options.errMsg.allFieldsFilled}</div>
        <div>
          <TextField
            onChange={handleInput}

            value={options.info.first_name}
            defaultValue={options.info.first_name}
            required
            label='First Name'
            name='first_name'
          >
          </TextField>

          <TextField

            onChange={handleInput}
            required
            label='Last Name'
            name='last_name'
            value={options.info.last_name}
            defaultValue={options.info.last_name}

          >
          </TextField>

        </div>
        <div>
          <TextField
            required
            error={options.errMsg.emailCorrect}

            name='email'
            type='email'
            label='Email'
            value={options.info.email}
            defaultValue={options.info.email}
            onChange={handleInput}
            style={{ width: "25ch" }}
            helperText={options.errMsg.emailCorrect}

          >
          </TextField>
          <TextField
            required
            error={options.errMsg.contactCorrect}

            type='number'
            value={options.info.contact}
            defaultValue={options.info.contact}
            name='contact'
            label='Mobile Number'
            onChange={handleInput}
            style={{ width: "25ch" }}
            helperText={options.errMsg.contactCorrect}
          >
          </TextField>
        </div>
        <div>
          <TextField
            required
            error={options.errMsg.org}

            name='Organization'
            type='text'
            label='Organization'
            value={options.info.Organization}
            defaultValue={options.info.Organization}
            onChange={handleInput}
            style={{ width: "25ch" }}
            helperText={options.errMsg.org}

          >
          </TextField>
          <TextField
            required
            error={options.errMsg.designation}

            value={options.info.Designation}
            defaultValue={options.info.Designation}
            name='Designation'
            label='Designation'
            onChange={handleInput}
            style={{ width: "25ch" }}
            helperText={options.errMsg.designation}
          >
          </TextField>
        </div>
        <div>
          <Autocomplete
            multiple
            id="tags-standard"
            options={subjectOpts}
            // defaultValue = {defaultSubjects}
            value={autoValue}
            onChange={handleSubjectInput}
            getOptionLabel={(option) => option}
            size='small'
            // filterOptions = {(options,state) => options.slice(0,4)}
            renderInput={(params) => (
              <TextField
                {...params}
                style={{ width: "52ch" }}
                variant="standard"
                label="Select Subjects"
                placeholder="Add Multiple Subjects as Tags"
              />
            )}
          />
        </div>
        <div style={{ textAlign: "center" }}>
          {/* <div>
            <TextField 
              
              style={{width:"35ch"}}
              select
              name = "gender"
              label = 'Gender' 
              onChange = {handleChangeGender}
              value = {options.gender}
              defaultValue = {options.gender}
            >
              <MenuItem key = {"Male"} value = {"Male"}>Male</MenuItem>
              <MenuItem key = {"Female"} value = {"Female"}>Female</MenuItem>
            </TextField>
          </div> */}

          <div>
            <TextField

              style={{ width: "35ch" }}
              select
              name='department'
              label='Department'
              onChange={handleChangeDepartment}
              value={options.dept}
              defaultValue={options.dept}
            >

              {options.deptArr.map((option) => (
                <MenuItem key={option.Dept_Name} value={option.Dept_Name}>{option.Dept_Name}</MenuItem>
              )
              )}
            </TextField>
          </div>

          <div>
            <TextField

              style={{ width: "35ch" }}
              select
              name="type"
              label='Examiner Type'
              onChange={handleChangeExaminerType}
              defaultValue={options.examinerType}
              value={options.examinerType}
            >
              <MenuItem key={"Internal"} value={"Internal"}>Internal</MenuItem>
              <MenuItem key={"External"} value={"External"}>External</MenuItem>
            </TextField>
          </div>
          <br />
        </div>
      </form>
    )
  }
  else {
    return (
      <Loading />
    )
  }

}