import React, { Component } from "react";
import "./Summary.css";
import { withStyles, makeStyles } from "@material-ui/core/styles";

import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { Loading } from "../Loading";
import footerLogo from "../../Assets/images/footerlogo1.png";
import TablePagination from "@material-ui/core/TablePagination";
import { ArrowForwardIos, ArrowBackIos } from "@material-ui/icons";
import IconButton from "@material-ui/core/IconButton";
import { InputLabel, MenuItem, Select } from "@material-ui/core";
import Pagination from "@material-ui/lab/Pagination";

import { v4 } from "uuid";

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#a02929",
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    "&:nth-of-type(odd)": {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

export default class SummaryTable extends Component {
  handleChangePage = (event, value, props) => {
    props.setCurrentPage(value);
  };

  handleChangeRowsPerPage = (event) => {
    this.props.setRowsPerPage(event.target.value);
    this.props.setCurrentPage(1);
  };

  constructor(props) {
    super(props);
  }

  render() {
    const classes = this.props.classes;
    var filteredHistory = this.props.filteredHistory;

    if (!filteredHistory || this.props.isLoading) {
      return <Loading />;
    }
    // const emptyRows = this.state.rowsPerPage - Math.min(this.state.rowsPerPage, filteredHistory.length - this.state.page * this.state.rowsPerPage);
    // var temp = Array.from(Array(filteredHistory.length).keys())
    // var RowOptions = temp.filter(n => n % 5 == 0)
    const extra_rows = this.props.totalRows % this.props.rowsPerPage;
    const pages_list = this.props.totalRows - extra_rows;

    return (
      <div className="table">
        {this.props.showLogo ? (
          <div style={{ margin: "auto", textAlign: "center" }}>
            <img
              style={{ padding: "20px", width: "80%" }}
              className="footer-logo"
              src={footerLogo}
              alt="logo-footer"
            />
            <h2 style={{ textAlign: "center" }}>Appointment Report</h2>
          </div>
        ) : null}
        <TableContainer
          component={Paper}
          style={{ width: "95%", margin: "10px auto" }}
        >
          <Table
            id="history-table"
            className={`${classes.table} ${
              filteredHistory && filteredHistory.length > 10
                ? "extra-large-table"
                : ""
            }`}
            aria-label="customized table"
          >
            <TableRow
              style={{
                backgroundColor: "#911717",
                color: "#fff",
                padding: "16px",
              }}
            >
              <StyledTableCell>
                Internal Examiner & Chair Person
              </StyledTableCell>
              <StyledTableCell>
                Internal Examiner / Paper Setter 2{" "}
              </StyledTableCell>
              <StyledTableCell>
                External Examiner / Paper Setter{" "}
              </StyledTableCell>
              <StyledTableCell>Graduation Level</StyledTableCell>
              <StyledTableCell>Semester</StyledTableCell>
              <StyledTableCell>Subject&nbsp;</StyledTableCell>
              <StyledTableCell>Exam Type&nbsp;</StyledTableCell>
              <StyledTableCell>Summer/Winter</StyledTableCell>
              <StyledTableCell>Regular/KT</StyledTableCell>
              <StyledTableCell>Date Sent &nbsp;</StyledTableCell>
            </TableRow>
            <TableBody>
              {filteredHistory && filteredHistory.length > 0 ? (
                filteredHistory
                  // .slice(this.state.page * this.state.rowsPerPage, this.state.page * this.state.rowsPerPage + this.state.rowsPerPage)
                  .map((row) => (
                    <StyledTableRow key={v4()}>
                      <StyledTableCell component="th" scope="row">
                        {row.Internal_1.Name}
                      </StyledTableCell>
                      <StyledTableCell>
                        {row.Internal_2 ? row.Internal_2.Name : "N/A"}
                      </StyledTableCell>
                      <StyledTableCell>{row.External_1.Name}</StyledTableCell>
                      <StyledTableCell>{row.Degree??"B.Tech"}</StyledTableCell>
                      <StyledTableCell align="center">
                        {row.semester}
                      </StyledTableCell>
                      <StyledTableCell>{row.Course}</StyledTableCell>
                      <StyledTableCell>{row.Exam_type}</StyledTableCell>
                      <StyledTableCell>{row.Summer_winter}</StyledTableCell>
                      <StyledTableCell>{row.Regular_KT}</StyledTableCell>
                      <StyledTableCell>
                        {new Intl.DateTimeFormat("en-US", {
                          day: "2-digit",
                          month: "short",
                          year: "numeric",
                        }).format(new Date(Date.parse(row.createdAt)))}
                      </StyledTableCell>
                    </StyledTableRow>
                  ))
              ) : (
                <StyledTableRow>
                  <StyledTableCell>
                    <div style={{ color: "red", fontWeight: "500" }}>NO</div>
                  </StyledTableCell>
                  <StyledTableCell>
                    <div style={{ color: "red", fontWeight: "500" }}>
                      APPOINTMENTS
                    </div>
                  </StyledTableCell>
                  <StyledTableCell>
                    <div style={{ color: "red", fontWeight: "500" }}>FOUND</div>
                  </StyledTableCell>
                  <StyledTableCell>
                    <div style={{ color: "red", fontWeight: "500" }}></div>
                  </StyledTableCell>
                  <StyledTableCell>
                    <div style={{ color: "red", fontWeight: "500" }}></div>
                  </StyledTableCell>
                  <StyledTableCell>
                    <div style={{ color: "red", fontWeight: "500" }}></div>
                  </StyledTableCell>
                  <StyledTableCell>
                    <div style={{ color: "red", fontWeight: "500" }}></div>
                  </StyledTableCell>
                  <StyledTableCell>
                    <div style={{ color: "red", fontWeight: "500" }}></div>
                  </StyledTableCell>
                  <StyledTableCell>
                    <div style={{ color: "red", fontWeight: "500" }}></div>
                  </StyledTableCell>
                </StyledTableRow>
              )}

              {this.props.totalRows && this.props.totalRows === 0 && (
                <TableRow style={{ height: 53 * this.props.rowsPerPage }}>
                  <TableCell colSpan={3} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>

        {this.props.showLogo ? (
          <div
            style={{
              fontWeight: "500",
              textAlign: "center",
              fontSize: "0.8rem",
            }}
          >
            Document Generated through Examiner Appointment System -<br />{" "}
            <a href="http://eas.kjsieit">
              {" "}
              Developed and Maintained By: Department of Information Technology,
              KJSIEIET
            </a>
          </div>
        ) : null}

        <div className="paginator">
          <div>
            {/* <IconButton  onClick={() => this.handleChangePage('prev',this.props)}><ArrowBackIos fontSize="medium" /></IconButton> */}

            <Pagination
              defaultPage={1}
              page={this.props.currentPage}
              onChange={(e, v) => this.handleChangePage(e, v, this.props)}
              count={Math.ceil(this.props.totalRows / this.props.rowsPerPage)}
            />

            {/* {this.props.currentPage} */}

            {/* <IconButton  onClick={() => this.handleChangePage('next',this.props)}><ArrowForwardIos fontSize="medium" /></IconButton> */}
          </div>

          <div className="rowsperpage">
            <InputLabel id="demo-simple-select-label" style={{ marginTop: 20 }}>
              Rows Per Page
            </InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={this.props.rowsPerPage}
              onChange={this.handleChangeRowsPerPage}
              label="Rows Per Page"
            >
              {[...Array(Math.floor(pages_list / 10)).keys()].map((pn) => (
                <MenuItem value={10 * pn + 10}>{10 * pn + 10}</MenuItem>
              ))}
              <MenuItem value={this.props.totalRows}>
                {this.props.totalRows}
              </MenuItem>
            </Select>
          </div>
        </div>

        {/* <TablePagination
            rowsPerPageOptions={RowOptions.slice(1,)}
            component="div"
            count={filteredHistory.length}
            rowsPerPage={this.state.rowsPerPage}
            page={this.state.page}
            onChangePage={this.handleChangePage}
            onChangeRowsPerPage={this.handleChangeRowsPerPage}
          /> */}
      </div>
    );
  }
}
