import React from 'react'
import logo from '../../Assets/images/Somaiya Logo.png'
import logo2 from '../../Assets/images/logo2.jpg'
import './AboutUs.css'
function AboutUs() {
  return (
    <div className="main-container">
      <div className="header-container">
        <div className="header-inner-container">
          <div className="first-column">
            <img className="first-col-img" src={logo} alt="logo" />
          </div>
          <div className="second-column" style={{ width: '85%', textAlign: 'center' }}>
            <h2 >
              K. J. Somaiya Institute of Technology, <br /> Sion,  Mumbai
            </h2>
            <div>An Autonomous Institute Permanently Affiliated to the University of Mumbai</div>
            <div>Accredited with 'A' Grade by NAAC (3.21 CGPA)</div>
          </div>

        </div>

      </div>
      <div className="info-container">
        <div>
          <h4 className="about-kjsieit">About KJSIT:</h4>
          <h6 className="about-kjsieit-info">
            K. J. Somaiya Institute of Technology (KJSIT), Sion, Mumbai is an Autonomous Institute affiliated to the University of Mumbai, recognized by UGC, and approved by AICTE and DTE - Maharashtra State. KJSIT offers UG Programmes in Computer Engineering, Information Technology, Artificial Intelligence & Data Science, Electronics & Telecommunication Engineering, Electronics Engineering, and PG Programme in Artificial Intelligence. KJSIT is accredited with ‘A’ Grade (CGPA 3.21) by the National Assessment & Accreditation Council (NAAC) of India for 5 Years and its 2 Programmes are accredited by the National Board of Accreditation (NBA). Recently, KJSIT was presented Best College Award by the University of Mumbai (Urban Region), ISTE (Maharashtra and Goa Section), and CSI (Mumbai Region). In the AICTE-CII Survey of Industry-Linked Technical Institutes, KJSIT is placed in Platinum Category for 2020, 2019 & 2018 and in Gold Category for 2017 & 2016 due to the strong industry interaction. KJSIT - Institution's Innovation Council (IIC) was listed as one of the Top 3 IICs & Top 5 IICs in Western Zone and as one of the Top 25 IICs & Top 40 IICs in the Nation for the Years 2018-19 & 2019-20 respectively by the Ministry of Education’s Innovation Cell. In the National Institutional Ranking Framework (NIRF) - Indian Rankings 2020, KJSIT has been placed in Rank Band 251-300 by the Ministry of Education. In Atal Ranking of Institutions on Innovation Achievements (ARIIA) 2020 by the Ministry of Education, KJSIT is placed in Rank Band ‘B’ indicating a rank in the Top 50 participating Private Institutions across India. The institute has been concentratedly focusing on Research & Development and bags several other awards and accolades to its credit.
          </h6>
        </div>
        <div>
          <h4 className="about-kjsieit">About Department of Information Technology:</h4>
          <h6 className="about-kjsieit-info">
            Department of Information Technology offers B.Tech. (Information Technology) programme under the Autonomous Status conferred to the institute by the UGC and the University of Mumbai since Academic Year 2021-22. The department offered B.E. (Information Technology) since Academic Year 2001-02 to 2020-21 and has facilitated the graduation of more than 1000 students by now. Department of Information Technology empowers its students to become the technological leaders of tomorrow by imparting quality education and knowledge of cutting-edge Information Technology. The department enormously encourages research and innovative projects for application areas like healthcare, societal contribution, automation, agriculture, security and surveillance, etc. using Artificial Intelligence, Machine Learning, Internet of Things, Big Data Analytics, Blockchain, Cloud Computing, etc. The department lays emphasis on project-based learning using model tools and technologies and this product <strong>"Examiner Appointment System"</strong> is an outcome of the same. The department has a strong tie-up with various industries and a culture of internship for all students to provide students with exposure to the environment they will become part of and make them industry-ready. Also, the department has a good record of both academics and placements. Prominence is laid on hands-on aspects through laboratories, co-curricular, and extra-curricular activities to help students explore interests and create a broader perspective, build higher self-esteem and learn essential life skills. Students of the department have been working responsibly on live projects as well as actively participate in various project competitions, hackathons, paper presentations, etc. and win prizes, bringing pride to the department.
          </h6>
        </div>
        <div>
          <h4 className="about-kjsieit">Vision of the Department:</h4>
          <h6 className="about-kjsieit-info">
            To be recognized as a center of excellence in the field of Information Technology where inquisitive minds of students are fostered, leading to skilled professionals and contributing citizens.
          </h6>
        </div>
        <div>
          <h4 className="about-kjsieit">Mission of the Department:</h4>
          <h6 className="about-kjsieit-info">
            <ul>
              <li>
                To produce industry ready engineers by imparting quality education and enhancing their proficiency with strong engineering foundation and in-depth knowledge of Information Technology.
              </li>
              <li>
                To encourage curiosity, creativity, innovative thinking, culture of continuous learning and multidisciplinary activities for developing state-of-the-art solutions to real-world problems.
              </li>
              <li>
                To serve humanity by creating socially responsible citizens with high ethical values who can communicate effectively and work independently or in groups.
              </li>
            </ul>
          </h6>
        </div>
        <div>
          <h4 className="about-kjsieit">Program Educational Objectives (PEOs):</h4>
          <h6 className="about-kjsieit-info">
            <ul>
              <li>
                Graduates will be able to apply knowledge of engineering fundamentals and cutting edge Information Technology to identify and implement innovative solutions for engineering problems and issues in society.
              </li>
              <li>
                Graduates will be able to exhibit their employability skills and practice the ethics of their profession with a sense of social responsibility.
              </li>
              <li>
                Graduates will have strong interpersonal skills and will engage in life-long learning to enhance their career positions, both as team members and leaders.
              </li>
            </ul>
          </h6>
        </div>
        <div>
          <h4 className="about-kjsieit">Program Specific Outcomes (PSOs):</h4>
          <h6 className="about-kjsieit-info">
            <ul>
              <li>
                Graduates will be able to apply standard software engineering practices in real-world project development to deliver efficient, secure and novel solutions.
              </li>
              <li>
                Graduates will be able to apply the contemporary trends in Information Technology like Internet of Things and Data Science in diverse application areas.
              </li>
            </ul>
          </h6>
        </div>
        <div>
          <h4 className="about-kjsieit">Team-Guides and Developers:</h4>
          <h6 className="about-kjsieit-info">
            <ol style={{ paddingLeft: '1.5rem' }}>
            <li>
                Dr. Suresh K. Ukarande, Principal
              </li>
              <li>
                Dr. Radhika Kotecha, Professor and Head - Department of Information Technology
              </li>
              <li>
                Mr. Akash Yadav, Student - Department of Information Technology
              </li>
              <li>
                Mr. Dakshit Vaviya, Student - Department of Information Technology
              </li>
              <li>
                Mr. Het Parekh, Student - Department of Information Technology
              </li>
              <li>
                Mr. Abdurrahman Chauhan, Student - Department of Information Technology
              </li>
              <li>
                Ms. Vaishnavi Tanna, Student - Department of Information Technology
              </li>
            </ol>
          </h6>
        </div>
        <div>
          <h4 className="about-kjsieit">For more details connect with us on:</h4>
          <a href="mailto:kjsieit.eas@somaiya.edu" target="_blank" className="about-kjsieit-info mail-to">
            kjsieit.eas@somaiya.edu
          </a>
        </div>
      </div>
    </div>
  )
}

export default AboutUs
