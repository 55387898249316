import React, { useState, useEffect, useRef } from "react";
import {
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  TextField,
  TextareaAutosize,
  Button,
} from "@material-ui/core";
import "./Summary.css";
import axios from "axios";

import { makeStyles } from "@material-ui/core/styles";
import { CSVLink, CSVDownload } from "react-csv";

import jsPDF from "jspdf";
import "jspdf-autotable";
import { useReactToPrint } from "react-to-print";
import SummaryTable from "./SummaryTable";
import DatePicker from "react-date-picker";
import { baseUrl } from "../../redux/baseUrl";
import Checkbox from "@material-ui/core/Checkbox";

import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import { Send, Error } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 200,
    maxWidth: 300,
  },
  CommonMailForm: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: "93%",
    },
  },
}));

function Summary() {
  const classes = useStyles();
  const [semesters, setSemesters] = useState([]);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [regKT, setRegKT] = useState("");
  const [season, setSeason] = useState("");
  const [examTypes, setExamTypes] = useState([]);
  const [examType, setExamType] = useState("");
  const [degree, setDegree] = useState("");
  const [isDisbaledSemDept, setIsDisableSemDept] = useState(true);
  const [semester, setSemester] = useState("");
  const [subject, setSubject] = useState("");
  const [allHistory, setAllHistory] = useState();
  const [filteredHistory, setFilteredHistory] = useState();
  const [csvData, setCSVData] = useState();
  const [allCsvData, setAllCsvData] = useState();
  const [showLogo, setShowLogo] = useState(false);
  const [departmentArr, setDepartmentArr] = useState([]);
  const [department, setDepartment] = useState("");
  const [year, setYear] = useState("");
  const [commonMail, setCommonMail] = useState(false);
  const [CommonMailTitle, setCommonMailTitle] = useState("");
  const [CommonMailBody, setCommonMailBody] = useState("");
  const [convenorOnly, setConvenorOnly] = useState(false);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalRows, setTotalRows] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const [allSubject, setAllSubject] = useState([]);

  useEffect(() => {
    setIsLoading(true);
    if (filteredHistory) {
      window.scrollTo(0, 0);
    }

    const opts = {
      url: baseUrl + `/appointments?page=${currentPage}&limit=${rowsPerPage}`,
      method: "GET",
      withCredentials: true,
    };
    axios(opts)
      .then((res) => {
        setTotalRows(res.data.count);
        setAllHistory([...res.data.results]);
        setFilteredHistory([...res.data.results]);
        let temp = [];
        res.data.results.forEach((his) => {
          let b = his.createdAt.split(/\D+/);
          let desDate = new Date(
            Date.UTC(b[0], --b[1], b[2], b[3], b[4], b[5], b[6])
          );
          let obj = {
            Internal_Examiner1_and_Chairperson: his.Internal_1.Name,
            Internal_Examiner2: his.Internal_2 ? his.Internal_2.Name : "N/A",
            External_Examiner1: his.External_1.Name,
            Season: his.Summer_winter,
            Regular_KT: his.Regular_KT,
            Course: his.Course,
            Exam_type: his.Exam_type,
            Semester: his.semester,
            Appointment_Date: desDate.toString().substring(0, 24),
          };
          temp.push(obj);
        });
        setCSVData([...temp]);
        setAllCsvData([...temp]);
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  }, [currentPage, rowsPerPage]);

  // Get all departments
  useEffect(() => {
    getAllDepartments("");
    axios
      .get(baseUrl + "/exams/", { withCredentials: true })
      .then((res) => {
        setExamTypes(res.data);
      })
      .catch((err) => console.log(err));
  }, []);

  const getAllDepartments = (degree) => {
    const url = `/departments${degree ? "?degree=" : ""}${degree}`;
    axios
      .get(baseUrl + url, { withCredentials: true })
      .then((res) => {
        setDepartmentArr(() => {
          return [...res.data];
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // Get all subjects
  useEffect(() => {
    axios.get(baseUrl + "/subjects", { withCredentials: true }).then((res) => {
      setAllSubject(res.data.map((d) => d.name));
    });
  }, []);

  const studentTypeChangeHandler = (e) => {
    setRegKT(e.target.value);
  };
  const examTypeChangeHandler = (e) => {
    setExamType(e.target.value);
  };

  const seasonChangeHandler = (e) => {
    setSeason(e.target.value);
  };

  const endDateChangeHandler = (e) => {
    setEndDate(e.target.value);
  };

  const startDateChangeHandler = (e) => {
    setStartDate(e.target.value);
  };
  const semesterChangeHandler = (e) => {
    setSemester(e.target.value);
  };
  const departmentChangeHandler = (e) => {
    setDepartment(e.target.value);
  };
  const subjectChangeHandler = (e) => {
    setSubject(e.target.value);
  };

  const degreeChangeHandler = (e) => {
    setSemesters(
      e.target.value === "M.Tech" ? [1, 2] : [1, 2, 3, 4, 5, 6, 7, 8]
    );
    getAllDepartments(e.target.value);
    setIsDisableSemDept(false);
    setDegree(e.target.value);
  };
  const filterData = (e) => {
    const temp = [];
    const temp2 = [];
    allHistory.forEach((his) => {
      let f = 0;
      if (degree !== "") {
        if (
          (typeof his.Degree === "undefined" && degree !== "B.Tech") ||
          (his.Degree && his.Degree !== degree)
        ) {
          f = 1;
        }
      }
      if (semester !== "") {
        if (his.semester !== semester) {
          f = 1;
        }
      }
      if (startDate !== "") {
        if (startDate > his.createdAt.substring(0, 10)) {
          f = 1;
        }
      }
      if (endDate !== "") {
        if (endDate < his.createdAt.substring(0, 10)) {
          f = 1;
        }
      }
      if (season !== "") {
        if (his.Summer_winter !== season) {
          f = 1;
        }
      }
      if (regKT !== "") {
        if (his.Regular_KT !== regKT) {
          f = 1;
        }
      }
      if (examType !== "") {
        if (his.Exam_type !== examType) {
          f = 1;
        }
      }
      if (department !== "") {
        if (his.Department !== department) {
          f = 1;
        }
      }
      if (year !== "") {
        if (his.Year != year.getFullYear()) {
          f = 1;
        }
      }
      if (subject !== "") {
        if (his.Course !== subject) {
          f = 1;
        }
      }
      if (f === 0) {
        temp.push(his);
        let b = his.createdAt.split(/\D+/);
        let desDate = new Date(
          Date.UTC(b[0], --b[1], b[2], b[3], b[4], b[5], b[6])
        );
        let obj = {
          Internal_Examiner1_and_Chairperson: his.Internal_1.Name,
          Internal_Examiner2: his.Internal_2 ? his.Internal_2.Name : "N/A",
          External_Examiner1: his.External_1.Name,
          Season: his.Summer_winter,
          Regular_KT: his.Regular_KT,
          Course: his.Course,
          Exam_type: his.Exam_type,
          Semester: his.semester,
          Appointment_Date: desDate.toString().substring(0, 24),
        };
        temp2.push(obj);
      }
    });
    setCSVData([...temp2]);
    setFilteredHistory([...temp]);
  };

  function submitCommonMail(e) {
    e.preventDefault();
    if (!CommonMailTitle || !CommonMailBody) {
      return false;
    }
    if (filteredHistory && filteredHistory.length === 0) {
      alert("No Examiners found, please filter again.....");
      return false;
    }

    let teacherSet = new Set();
    filteredHistory.forEach((app) => {
      teacherSet.add(app.Internal_1.email);
      if (!convenorOnly) {
        teacherSet.add(app.External_1.email);
        if (app.Internal_2) {
          teacherSet.add(app.Internal_2.email);
        }
      }
    });

    let teacherArr = Array.from(teacherSet);

    let data = {
      title: CommonMailTitle,
      body: CommonMailBody,
      teacherArr: teacherArr,
    };

    axios
      .post(baseUrl + "/appointments/broadcastmessage", data, {
        withCredentials: true,
      })
      .then((res) => {
        alert("Message Broadcasted Successfully");
        setCommonMailTitle("");
        setCommonMailBody("");
        setCommonMail(false);
      })
      .catch((err) => {
        console.log(err);
        alert("Sorry, unexpected error occured....");
      });
  }

  function closeCommonMail() {
    setCommonMail(false);
    setCommonMailBody("");
    setCommonMailTitle("");
  }

  useEffect(() => {
    if (showLogo === true) {
      handlePrint();
    }
  }, [showLogo]);

  const SummaryRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => SummaryRef.current,
    documentTitle: "AppointmentReport",
    onAfterPrint: () => setShowLogo(false),
    onBeforeGetContent: () => setShowLogo(false),
  });

  const resetFilter = (e) => {
    setFilteredHistory([...allHistory]);
    setEndDate("");
    setStartDate("");
    setCSVData([...allCsvData]);
    setSemester("");
    setExamType("");
    setRegKT("");
    setSeason("");
    setDepartment("");
    setYear("");
    setDegree("");
    setIsDisableSemDept(true);
  };

  const props = {
    classes: classes,
    isLoading: isLoading,
    currentPage: currentPage,
    setCurrentPage: setCurrentPage,
    totalRows: totalRows,
    rowsPerPage: rowsPerPage,
    setRowsPerPage: setRowsPerPage,
    filteredHistory: filteredHistory,
    showLogo: showLogo,
    ref: SummaryRef,
  };

  if (!filteredHistory) {
    return (
      <SummaryTable
        classes={classes}
        filteredHistory={filteredHistory}
        showLogo={showLogo}
        ref={SummaryRef}
      />
    );
  }
  return (
    <div>
      <div className="filtered-download">
        <div className="filter-body">
          <h2
            style={{
              color: "#911717",
              fontWeight: "600",
              textTransform: "uppercase",
            }}
          >
            Export Filtered Report
          </h2>
          <div className="first-row">
            <div
              className="Season common-first"
              style={{ display: "inline-flex" }}
            >
              {/* <div style={{ marginTop: '30px' }}>Select Season:</div> */}
              <FormControl className={classes.formControl}>
                <InputLabel id="season-label">Season</InputLabel>
                <Select
                  labelId="season-label"
                  value={season}
                  onChange={seasonChangeHandler}
                >
                  <MenuItem key={"Summer"} value="Summer">
                    First Half (Summer)
                  </MenuItem>
                  <MenuItem key={"Winter"} value="Winter">
                    Second Half (Winter)
                  </MenuItem>
                </Select>
              </FormControl>
            </div>
            <div
              className="degree common-first"
              style={{ display: "inline-flex" }}
            >
              <FormControl className={classes.formControl}>
                <InputLabel id="degree-label">Graduation Level</InputLabel>
                <Select
                  labelId="degree-label"
                  value={degree}
                  onChange={degreeChangeHandler}
                >
                  <MenuItem value="B.Tech">B.Tech</MenuItem>
                  <MenuItem value="M.Tech">M.Tech</MenuItem>
                </Select>
              </FormControl>
            </div>
            <div
              className="semester common-first"
              style={{ display: "inline-flex" }}
            >
              {/* <div style={{ marginTop: '30px' }}>Select Semester:</div> */}
              <FormControl className={classes.formControl}>
                <InputLabel id="semester-label">Semester</InputLabel>
                <Select
                  disabled={isDisbaledSemDept}
                  labelId="semester-label"
                  value={semester}
                  onChange={semesterChangeHandler}
                  title={
                    isDisbaledSemDept
                      ? "Please select Graduation level first"
                      : ""
                  }
                >
                  {semesters.map((sem) => (
                    <MenuItem key={sem} value={sem}>
                      {sem}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div
              className="department common-first"
              style={{ display: "inline-flex" }}
            >
              <FormControl className={classes.formControl}>
                <InputLabel id="department-label">Department</InputLabel>
                <Select
                  disabled={isDisbaledSemDept}
                  labelId="department-label"
                  value={department}
                  onChange={departmentChangeHandler}
                  title={
                    isDisbaledSemDept
                      ? "Please select Graduation level first"
                      : ""
                  }
                >
                  {departmentArr.map((dept) => (
                    <MenuItem key={dept._id} value={dept.Dept_Name}>
                      {dept.Dept_Name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          </div>
          <div className="second-row">
            <div className="reg_kt">
              <FormControl className={classes.formControl}>
                <InputLabel id="reg_kt-label">Student Type</InputLabel>
                <Select
                  labelId="reg_kt-label"
                  value={regKT}
                  onChange={studentTypeChangeHandler}
                >
                  <MenuItem key={"Regular"} value="Regular">
                    Regular
                  </MenuItem>
                  <MenuItem key={"KT"} value="KT">
                    KT
                  </MenuItem>
                </Select>
              </FormControl>
            </div>
            <div className="exam-type">
              <FormControl className={classes.formControl}>
                <InputLabel id="examType-label">Exam Type</InputLabel>
                <Select
                  labelId="examType-label"
                  value={examType}
                  onChange={examTypeChangeHandler}
                >
                  {examTypes.map((type) => (
                    <MenuItem key={type._id} value={type.Exam_Name}>
                      {type.Exam_Name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div className="subject">
              <FormControl className={classes.formControl}>
                <InputLabel id="subject-label">Subject</InputLabel>
                <Select
                  labelId="subject-label"
                  value={subject}
                  onChange={subjectChangeHandler}
                >
                  {allSubject.map((sub, index) => (
                    <MenuItem key={sub + " " + index} value={sub}>
                      {sub}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          </div>
          <div className="third-row">
            <div>
              <FormControl className={classes.formControl}>
                <div style={{ color: "rgba(0, 0, 0, 0.54)" }}>Year</div>
                <DatePicker
                  onChange={setYear}
                  value={year}
                  maxDetail={"decade"}
                  format={"yyyy"}
                  clearIcon={null}
                />
              </FormControl>
            </div>
            <div className="from-date">
              <label
                htmlFor="start"
                style={{ fontWeight: "bold", display: "block" }}
              >
                From &nbsp;
              </label>
              <input
                type="date"
                name="start"
                onChange={startDateChangeHandler}
                max={
                  endDate ? endDate : new Date().toISOString().substring(0, 10)
                }
                value={startDate}
              />
            </div>
            <div className="end-date">
              <label
                htmlFor="start"
                style={{ fontWeight: "bold", display: "block" }}
              >
                &nbsp;To &nbsp; &nbsp;{" "}
              </label>
              <input
                type="date"
                name="start"
                onChange={endDateChangeHandler}
                min={startDate}
                max={new Date().toISOString().substring(0, 10)}
                value={endDate}
              />
            </div>
          </div>
          <div className="fourth-row">
            <div className="get-filtered">
              <button className={`filter-btn`} onClick={filterData}>
                Get Filtered Report
              </button>
            </div>
            <div className="reset-filter">
              <button className="filter-btn" onClick={resetFilter}>
                Reset Filter
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className="Export-report">
        <button
          className={`filter-btn ${
            !(filteredHistory && filteredHistory.length > 0)
              ? "not-allowed"
              : ""
          } `}
        >
          {filteredHistory && filteredHistory.length > 0 ? (
            <CSVLink
              data={csvData ? csvData : "Empty"}
              filename={"AppointmentReport.csv"}
              className={`exportcsvlink ${
                !(filteredHistory && filteredHistory.length > 0)
                  ? "dont-allow"
                  : ""
              } `}
              target="_blank"
              disabled={!(filteredHistory && filteredHistory.length > 0)}
            >
              Download Report as CSV
            </CSVLink>
          ) : (
            <span>Download Report as CSV</span>
          )}
        </button>

        <button
          onClick={
            filteredHistory.length > 0 ? () => setCommonMail(true) : void 0
          }
          className={`filter-btn ${
            !(filteredHistory && filteredHistory.length > 0)
              ? "not-allowed-mail"
              : ""
          } `}
          style={{ float: "right" }}
        >
          {" "}
          Broadcast Message <Error style={{ fontSize: 20, paddingBottom: 3 }} />
        </button>
        <Dialog
          open={commonMail}
          aria-labelledby="alert-dialog-title"
          onClose={closeCommonMail}
        >
          <DialogTitle
            style={{ backgroundColor: "#a02929", color: "#fff" }}
            align="center"
            id="alert-dialog-title"
          >
            Important Broadcast
          </DialogTitle>

          <DialogContent>
            <Alert elevation={2} variant="filled" severity="error">
              You will be sending the mail to all the examiners in the filtered
              appointment list. This mail cannot be changed or undone after
              being sent, please be careful before proceeding.
            </Alert>
            <br />
            <form
              className={classes.CommonMailForm}
              onSubmit={submitCommonMail}
            >
              <div>
                <TextField
                  value={CommonMailTitle}
                  required
                  label="Title"
                  name="CommonMailTitle"
                  onChange={(e) => setCommonMailTitle(e.target.value)}
                ></TextField>
              </div>
              <br />
              <div>
                <Checkbox
                  value={CommonMailTitle}
                  style={{ color: "#a02929" }}
                  label="Convenor Only"
                  name="convenorOnly"
                  onChange={(e) => setConvenorOnly(!convenorOnly)}
                >
                  {" "}
                </Checkbox>{" "}
                Convenor(s) Only
              </div>

              <br />

              <div>
                <TextareaAutosize
                  value={CommonMailBody}
                  rowsMin={10}
                  rowsMax={10}
                  placeholder="Enter Your Message*"
                  style={{ width: "93%", marginLeft: 10 }}
                  InputProps={{
                    style: { padding: 10 },
                  }}
                  name="CommonMailBody"
                  onChange={(e) => setCommonMailBody(e.target.value)}
                  required
                ></TextareaAutosize>
              </div>
              <DialogActions>
                <Button
                  type="submit"
                  style={{
                    marginRight: "20px",
                    backgroundColor: "#a02929",
                    color: "#fff",
                    borderColor: "#fff",
                  }}
                  variant="outlined"
                >
                  Send&nbsp;
                  <Send />
                </Button>
              </DialogActions>
            </form>
          </DialogContent>
        </Dialog>
      </div>
      <SummaryTable {...props} />
    </div>
  );
}

export default Summary;
