export const LOGIN_REQUEST = 'LOGIN_REQUEST'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const LOGIN_FAILURE = 'LOGIN_FAILURE'

export const LOGOUT_REQUEST = 'LOGOUT_REQUEST'
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS'

export const CHECK_STATUS_REQUEST = 'CHECK_STATUS_REQUEST'
export const CHECK_STATUS_FAILURE = 'CHECK_STATUS_FAILURE'
export const CHECK_STATUS_SUCCESS = 'CHECK_STATUS_SUCCESS'
