import React, { useEffect } from 'react'
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { lighten, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import Tooltip from '@material-ui/core/Tooltip';
import SendIcon from '@material-ui/icons/Send';
import Button from '@material-ui/core/Button';
import ErrorOutlineOutlinedIcon from '@material-ui/icons/ErrorOutlineOutlined';
import axios from 'axios'
import { baseUrl } from '../../redux/baseUrl';
import Chip from '@material-ui/core/Chip';
import { Loading } from '../Loading';

function createData(faculty, subject, course_id, type) {
  return { faculty, subject, course_id, type };
}

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  // { id: 'degree', numeric: false, disablePadding: false, label: 'Graduation Level' },
  { id: 'faculty', numeric: false, disablePadding: false, label: 'Faculty' },
  { id: 'subject', numeric: false, disablePadding: false, label: 'Subject' },
  { id: 'course_id', numeric: false, disablePadding: false, label: 'Course Code' },
  { id: 'type', numeric: false, disablePadding: false, label: 'Examiner / Paper Setter Type' },
];

function EnhancedTableHead(props) {
  const { classes, order, orderBy, numSelected, rowCount, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox"></TableCell>
        {/* <TableCell padding="checkbox">
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ 'aria-label': 'select all desserts' }}
            classes={{checked:classes.checkColor}}
          />
        </TableCell> */}
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,

  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
  {
    color: '#a02929',
    backgroundColor: lighten("#a02929", 0.9),
    borderColor: "#a02929"
  },
  wrapIcon: {
    verticalAlign: 'middle',
    display: 'inline-flex'
  },
  title: {
    flex: '1 1 100%',
    fontSize: "18px"
  },
  highlightError: {
    color: theme.palette.error.dark,
    backgroundColor: lighten(theme.palette.error.light, 0.85)
  },
  highlightWarn: {
    color: theme.palette.warning.dark,
    backgroundColor: lighten(theme.palette.warning.light, 0.85)
  }
}));

const EnhancedTableToolbar = (props) => {
  let options = props.enhancedTableToolBarOptions
  function handleSubmit() {
    if (window.confirm('Send Appointment to the selected examiners?')) {
      let selected = props.selected
      if (props.numSelected >= 2 && props.numSelected < 4) {
        let send_info = { "Exam_type": options.exam_type, "Department": options.appoint_info.dept, "Course": options.appoint_info.subject }
        let i = 0
        let e = 0
        selected.forEach(selected => {
          let examiner_name = selected.split("#")[0]
          let examiner_type = selected.split("#")[1]
          let teacherArr_copy = [...options.teacherArr]
          teacherArr_copy.forEach(teacher => {
            if (teacher.Name == examiner_name) {
              if (examiner_type == "Internal") {
                i += 1
                let teacher_copy = {}
                Object.assign(teacher_copy, teacher);
                delete teacher_copy['subjectData']
                send_info["Internal_" + i] = teacher_copy

              } else {
                e += 1
                let teacher_copy = {}
                Object.assign(teacher_copy, teacher);
                delete teacher_copy['subjectData']
                send_info["External_" + e] = teacher_copy

              }
            }
          });
        });
        send_info["AppointedBy"] = options.currentUser
        send_info["course_scheme"] = options.appoint_info.subject.scheme
        send_info["course_code"] = options.appoint_info.subject.code
        send_info["Year"] = Number(options.appoint_info.year)
        send_info['Summer_winter'] = options.appoint_info.season
        send_info['Regular_KT'] = options.appoint_info.reg_kt
        send_info['Elective'] = options.appoint_info.elective
        send_info['Degree'] = options.appoint_info.degree

        delete send_info.Course.teachers
        const axios_opts = {
          url: baseUrl + '/appointments',
          method: "POST",
          data: send_info,
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json;charset=UTF-8'

          },
          withCredentials: true
        }
        axios(axios_opts)

          .then(res => {
            alert("Your Appointment was sent Successfully")
            // setTimeout(() => {
            //   options.setFlash("")
            // }, 5000)
            options.setSelected([])
            options.setConvener("")
          })
          .catch(err => console.log(err))
      }
    }
  }
  const classes = useToolbarStyles();
  const { numSelected } = props;
  let external = 0
  let internal = 0

  props.selected.forEach(el => {
    let type = el.split("#")[1]
    let faculty = el.split("#")[0]
    if (type == "External") {
      external += 1
      if (external == 1) {
        options.setConvener(faculty)
      }

    } else {
      internal += 1
    }
    if (external == 0) {
      options.setConvener("")
    }
  });

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0 && (internal <= 2 || external <= 1),
        [classes.highlightError]: internal > 2 || external > 1 || (internal == 2 && options.exam_type !== 'Theory'),
        [classes.highlightWarn]: props.validate === false,
        [classes.highlightWarn]: (numSelected < 2 && options.exam_type !== 'Theory' && external < 2) || (numSelected < 3 && options.exam_type === 'Theory' && external < 2)
      })}
    >
      {
        props.validate === false ? (
          <Typography className={classes.title, classes.wrapIcson} color="inherit" variant="subtitle1" component="div">
            <small><ErrorOutlineOutlinedIcon className={classes.wrapIcon} fontSize='small' /></small> &nbsp; Note : Select All The Above Fields Before Appointing & Select 2 Interanl and 1 Internal Examiner
          </Typography>
        ) :
          internal > 2 ? (
            <Typography className={classes.title, classes.wrapIcon} color="inherit" variant="subtitle1" component="div">
              <small><ErrorOutlineOutlinedIcon className={classes.wrapIcon} fontSize='small' /></small> &nbsp; ERROR : Internal Examiner Limit Exceeded ({internal}) [MAX 2]
            </Typography>
          ) :
            internal > 1 && options.exam_type !== 'Theory' ? (
              <Typography className={classes.title, classes.wrapIcon} color="inherit" variant="subtitle1" component="div">
                <small><ErrorOutlineOutlinedIcon className={classes.wrapIcon} fontSize='small' /></small> &nbsp; ERROR : Internal Examiner Limit Exceeded ({internal}) [MAX 1]
              </Typography>
            ) :
              external > 1 ? (
                <Typography className={classes.title, classes.wrapIcon} color="inherit" variant="subtitle1" component="div">
                  <small><ErrorOutlineOutlinedIcon className={classes.wrapIcon} fontSize='small' /></small> &nbsp; ERROR : External Examiner Limit Exceeded ({external}) [MAX 1]
                </Typography>
              ) :
                (numSelected == 3 && options.exam_type === 'Theory') || (numSelected == 2 && options.exam_type !== 'Theory') ? (
                  <Typography className={classes.title} color="inherit" variant="subtitle1" component="div">
                    {external} External Selected and {internal} Internal Selected
                  </Typography>
                ) :
                  (numSelected < 3 && options.exam_type === 'Theory') || (numSelected < 2 && options.exam_type !== 'Theory') ? (
                    <Typography className={classes.title} color="inherit" variant="subtitle1" component="div">
                      <small><ErrorOutlineOutlinedIcon className={classes.wrapIcon} fontSize='small' /></small> &nbsp; Note : Not Enough Examiners Selected
                    </Typography>
                  ) : (<div></div>)}

      {(internal == 2 && options.exam_type === "Theory" || internal == 1 && options.exam_type !== "Theory") && external == 1 && options.validate === true ? (
        <Tooltip title="Appoint">
          <Button
            onClick={() => handleSubmit()}
            color="default"
            className={classes.button, classes.highlight}
            endIcon={<SendIcon />}
          >
            Appoint
          </Button>

        </Tooltip>
      ) : (<div></div>
      )}
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};


const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    padding: "50px",
    paddingTop: "0px"
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
    padding: "20px"
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  highlight_row: {
    backgroundColor: "#e8f4f8 !important",

  },
  none: {
    backgroundColor: "#fff!important"
  },
  checkColor: {
    color: "#a02929 !important"
  },

  header: {
    fontStyle: "Courier,Arial,sans-shreiff,TimesNewRoman",

  },







}));


export default function AppointmentTable(props) {
  const classes = useStyles();
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('subject');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [teacherArr, setTeacherArr] = React.useState();
  const [rows, setRows] = React.useState([]);
  const [appointInfo, setAppointInfo] = React.useState([]);
  const [currentUser, setCurrentUser] = React.useState();
  const [convenor, setConvener] = React.useState();


  useEffect(() => {
    axios.get(baseUrl+'/examiner',{withCredentials:true})
      .then(res => {
        setTeacherArr(() => {
          return [...res.data]
        })

        axios.get(baseUrl+'/user/checkstatus', { withCredentials: true })
          .then(res => {
            setCurrentUser(res.data.user.email)
          })
      })
  }, [])

  
  useEffect(() => {
    var temp_rows = []
    var temp_appoint_info = { subject: "", dept: "", season: "", year: "", reg_kt: "" }
    var options = props.options
    var course_obj
    temp_appoint_info.season = options.season
    temp_appoint_info.year = options.year
    temp_appoint_info.reg_kt = options.reg_kt
    temp_appoint_info.elective = options.elective
    temp_appoint_info.degree = options.degree

    if (teacherArr && teacherArr.length === 0) {
      setRows("No Examiners Found")
      return
    }

    if(teacherArr){
      teacherArr.forEach(teacher => {
        let subjects = []
        let codes = []
  
        if (teacher.department._id == options.department) {
  
          temp_appoint_info.dept = teacher.department.Dept_Name
        }
        if (teacher.subjectData) {
          teacher.subjectData.forEach(subject => {
            subjects.push(subject.name)
            codes.push(subject.code)
          });
        }
  
        if (options.subject_code) {
          let subject_name = ""
          options.subjectArr.forEach(subject => {
            if (options.subject_code == subject.code) {
              subject_name = subject.name
              course_obj = subject
            }
          });
          if (subjects.indexOf(subject_name) != -1) {
            temp_appoint_info.subject = course_obj
            temp_rows.push(createData(teacher.Name, [subject_name], [options.subject_code], teacher.type))
          }
        }
        else if (options.subjectArr[0] && Object.keys(options.subjectArr[0]).length != 0) {
          let filter_subjects = []
          let filter_codes = []
          options.subjectArr.forEach(subject => {
            if (subjects.indexOf(subject.name) != -1) {
              filter_subjects.push(subject.name)
              filter_codes.push(subject.code)
            }
          });
          if (filter_subjects.length > 0) {
            temp_rows.push(createData(teacher.Name, filter_subjects, filter_codes, teacher.type))
          }
        }
        else if (options.department) {
          const filter_dept = options.department
          if (teacher.department._id === filter_dept) {
            temp_rows.push(createData(teacher.Name, subjects, codes, teacher.type))
          }
        }
        else {
          temp_rows.push(createData(teacher.Name, subjects, codes, teacher.type))
        }
  
      })
      if(temp_appoint_info.dept === ''){
        options.departmentArr.forEach(dept => {
          if(dept._id === options.department){
            temp_appoint_info.dept = dept.Dept_Name
          }
        })
      }
      setSelected([])
      setAppointInfo(temp_appoint_info)
      setRows(temp_rows)
      setPage(0)
    }
    

  }, [props.options])

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleClick = (event, faculty, type) => {

    const selectedIndex = selected.indexOf(faculty + "#" + type);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, faculty + "#" + type);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }

    setSelected(newSelected);

  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (faculty, type) => selected.indexOf(faculty + "#" + type) !== -1;

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);
  var temp = Array.from(Array(rows.length).keys())
  var RowOptions = temp.filter(n => n % 5 == 0)
  var enhancedTableToolBarOptions = {
    appoint_info: appointInfo,
    teacherArr: teacherArr,
    exam_type: props.options.examType,
    validate: props.validate,
    currentUser: currentUser,
    // setFlash: props.setFlash,
    setSelected: setSelected,
    setConvener: setConvener
  }
  if (teacherArr) {

    return (
      <div className={classes.root}>
        <Paper className={classes.paper}>
          {/* <div className={classes.flashMessage}>{flash}</div> */}
          <TableContainer>
            <Table
              className={classes.table}
              aria-labelledby="tableTitle"
              size={'medium'}
              aria-label="enhanced table"
            >
              <EnhancedTableHead
                classes={classes}
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}

                onRequestSort={handleRequestSort}
                rowCount={rows.length}
              />
              <TableBody>
                {
                  Array.isArray(rows) ?
                    stableSort(rows, getComparator(order, orderBy))
                      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                      .map((row, index) => {
                        const isItemSelected = isSelected(row.faculty, row.type);
                        const labelId = `enhanced-table-checkbox-${index}`;
                        return (
                          <TableRow
                            hover
                            selected
                            onClick={(event) => props.validate ? handleClick(event, row.faculty, row.type) : ""}
                            role="checkbox"
                            aria-checked={isItemSelected}
                            tabIndex={-1}
                            key={row.faculty + index + "row"}
                            selected={isItemSelected}
                            classes={(props.validate === true) ? { selected: classes.highlight_row } : { selected: classes.none }}
                          >
                            <TableCell key={"checkboxCell" + index} padding="checkbox">
                              {/* {(disableSelection[row.type] == false)? */}
                              {(props.validate) ?
                                <Checkbox
                                  key={"checkbox" + index}
                                  checked={isItemSelected}
                                  classes={{ checked: classes.checkColor }}
                                  inputProps={{ 'aria-labelledby': labelId }}
                                /> : <div></div>}

                            </TableCell>
                            <TableCell key={"faculty" + index} component="th" id={labelId} scope="row">
                              {row.faculty}
                            </TableCell>
                            <TableCell key={"subject" + index} align="left">{row.subject.map((sub) => <Chip size="small" style={{ margin: 2 }} variant='outlined' label={sub}></Chip>)}</TableCell>
                            <TableCell key={"course_id" + index} align="left">{row.course_id.map((sub) => <Chip size="small" style={{ margin: 2 }} variant='outlined' label={sub}></Chip>)}</TableCell>
                            <TableCell key={"convenor" + index} align="left">{row.type}
                              {/* &nbsp; {row.faculty == convenor ? <Chip size="small" label="Convenor" color="primary" /> : ""} */}
                            </TableCell>
                          </TableRow>
                        );
                      }) : 
                      <TableRow>
                        <TableCell></TableCell>
                        <TableCell style={{ color: "red", fontSize: 20 }}>NO EXAMINERS FOUND</TableCell>
                      </TableRow>
                    }
                {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={4} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <EnhancedTableToolbar enhancedTableToolBarOptions={enhancedTableToolBarOptions} selected={selected} numSelected={selected.length} />
          <TablePagination
            rowsPerPageOptions={RowOptions.slice(1,)}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </Paper>
      </div>
    )

  }
  else {
    return (
      <Loading />
    )

  }
}