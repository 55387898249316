import React, { useState, useEffect } from "react";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import { makeStyles, useTheme, lighten, withStyles } from "@material-ui/core/styles";
import InputLabel from "@material-ui/core/InputLabel";
import ExpandMoreRoundedIcon from "@material-ui/icons/ExpandMoreRounded";
import axios from "axios";
import "./Appointment.css";
import Typography from "@material-ui/core/Typography";
import AppointmentTable from "./AppointmentTable";
import { Radio, RadioGroup, FormControlLabel } from "@material-ui/core";
import DoubleArrowIcon from "@material-ui/icons/DoubleArrow";
import DatePicker from "react-date-picker";
import { baseUrl } from "../../redux/baseUrl";

const useStyles = makeStyles((theme) => ({
	formControl: {
		margin: theme.spacing(1),
		minWidth: 120,
		maxWidth: 300,
	},
	chips: {
		display: "flex",
		flexWrap: "wrap",
	},
	chip: {
		margin: 2,
	},
	noLabel: {
		marginTop: theme.spacing(3),
	},
	// flashMessage: {
	//   color: theme.palette.success.dark,
	//   backgroundColor: lighten(theme.palette.success.light, 0.85),
	//   fontSize: '24px',
	//   textAlign: 'center'
	// },
	reg_kt: {
		display: "inline",
	},
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
	PaperProps: {
		style: {
			maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
			width: 250,
		},
	},
};

let subNames = [];
let subUI = null;

const sems = [1, 2, 3, 4, 5, 6, 7, 8];
const RedRadio = withStyles({
	root: {
		color: "#911717",
		"&$checked": {
			color: "#911717",
		},
	},
})((props) => <Radio style={{ cursor: "pointer" }} color="default" {...props} />);

function Appointment(props) {
	const classes = useStyles();
	const theme = useTheme();
	const [semester, setSemester] = useState("");
	const [subject, setSubject] = useState("");
	const [subjectArr, setSubjectArr] = useState([{}]);
	const [teacherArr, setTeacherArr] = useState([{}]); // use teacherArr to display in table Het
	const [department, setDepartment] = useState("");
	const [scheme, setScheme] = useState("");
	const [departmentArr, setDepartmentArr] = useState([{}]);
	const [totalDepartmentArr, setTotalDepartmentArr] = useState([{}]);
	const [disableDept, setdisableDept] = useState(true);
	const [disableSubs, setdisableSubs] = useState(true);
	const [examTypes, setExamTypes] = useState([{}]);
	const [selectedExamTypeArr, setSelectedExamTypeArr] = useState([]);
	const [season, setSeason] = useState("Summer");
	const [degree, setDegree] = useState("B.Tech");
	const [date, setDate] = useState(new Date());
	const [reg_kt, setReg_kt] = useState("Regular");
	const [elective, setElective] = useState("Regular");
	const [semsArr, setSemsArr] = useState([1, 2, 3, 4, 5, 6, 7, 8]);
	const [examType, setExamType] = useState("Theory");
	// const [showOption, setShowOption] = useState(false)
	const [isAllFieldsFilled, setIsAllFieldsFilled] = useState(false);
	// const [flash, setFlash] = React.useState("")

	// const dispatch = useDispatch()

	useEffect(() => {
		// dispatch(checkStatus())

		axios.get(baseUrl + "/examiner", { withCredentials: true }).then((res) => {
			setTeacherArr(() => {
				return [...res.data];
			});
		});

		// Get All Types of exams such as wtiten, oral
		axios
			.get(baseUrl + "/exams/", { withCredentials: true })
			.then((res) => {
				setExamTypes(res.data);
			})
			.catch((err) => console.log(err));
	}, []);

	useEffect(() => {
		// Get all departments
		axios
			.get(baseUrl + "/departments?degree="+degree, { withCredentials: true })
			.then((res) => {
				setDepartmentArr(() => {
					return [...res.data];
				});
				setTotalDepartmentArr(() => {
					return [...res.data];
				});
			})
			.catch((err) => {
				console.log(err);
			});
	}, [degree]);

	useEffect(() => {
		// if ((semester !== '' && semester !== null) && (department !== '' && department !== null) && (subject !== '' && subject !== null) && (examTypeArr.length > 0)) {

		if (semester && department && subject && examType) {
			setIsAllFieldsFilled(true);
		} else {
			setIsAllFieldsFilled(false);
		}
	}, [semester, department, subject, examType]);

	const semesterChangeHandler = (e) => {
		setSemester(e.target.value);
		let dept_arr = [];
		if (degree === "M.Tech"){
			totalDepartmentArr.forEach((dept) => {
					dept_arr.push(dept);
			});
		}
		else if (e.target.value == 1 || e.target.value == 2) {
			totalDepartmentArr.forEach((dept) => {
				if (dept.Dept_Name == "Department of Basic Sciences and Humanities") {
					dept_arr.push(dept);
				}
			});
		} else {
			totalDepartmentArr.forEach((dept) => {
				if (dept.Dept_Name != "Department of Basic Sciences and Humanities") {
					dept_arr.push(dept);
				}
			});
		}
		setDepartmentArr(dept_arr);
		setDepartment("");
		setScheme("");
		setSubject("");
		setdisableDept(false);

		// code for handling the condition when if the subject and department is selected but after that semester is changed
		if (department && !subject) {
			axios.get(baseUrl + `/subjects/${e.target.value}/department/${department}/${degree}`, { withCredentials: true }).then((res) => {
				setSubjectArr(() => {
					return [...res.data];
				});
			});
		}

		if (subject) {
			axios.get(baseUrl + `/subjects/${e.target.value}/department/${department}/${degree}`, { withCredentials: true }).then((res) => {
				setSubject("");
				setSubjectArr(res.data);
			});
		}
	};

	const departmentChangeHandler = (e) => {
		setDepartment(e.target.value);
		setScheme("");
	};

	const schemeChangeHandler = (e) => {
		setScheme(e.target.value);
		setSubject("");
		axios.get(baseUrl + `/subjects/${semester}/department/${department}/${degree}/${e.target.value}`, { withCredentials: true }).then((res) => {
			setSubjectArr(() => {
				return [...res.data];
			});
		});

		setdisableSubs(false);
	};

	const subjectChangeHandler = (event) => {
		setSubject(event.target.value);
	};

	const examTypeHandler = (e) => {
		if (e.target.checked) {
			const examTypeArr = [...selectedExamTypeArr];
			examTypeArr.push(e.target.value);
			setSelectedExamTypeArr(examTypeArr);
		} else {
			const examTypeArr = [...selectedExamTypeArr];
			const index = examTypeArr.indexOf(e.target.value);
			examTypeArr.splice(index, 1);
			setSelectedExamTypeArr(examTypeArr);
		}
	};

	const degreeChangeHandler = (e) => {
		setSemester("");
		setDepartment("");
		setSubject("");
		setSeason("Summer")
		let deg = e.target.value
		setDegree(deg)
		if (deg === "B.Tech"){
			setSemsArr([1, 2, 3, 4, 5, 6, 7, 8])
		}
		else{
			setSemsArr([1, 2])
		}
	}

	const seasonChangeHandler = (e) => {
		setSemester("");
		setDepartment("");
		setSubject("");
		let s = e.target.value;
		setSeason(s);
		// if (s === 'Summer') {
		//   setSemsArr(sems.filter((sem) => sem % 2 == 1))
		// }
		// else {
		//   setSemsArr(sems.filter((sem) => sem % 2 == 0))
		// }
	};
	const options = {
		subjectArr: subjectArr,
		semester: semester,
		examType: examType,
		// selectedExamTypeArr: selectedExamTypeArr,
		department: department,
		departmentArr: departmentArr,
		subject_code: subject,
		season: season,
		year: date.getFullYear(),
		reg_kt: reg_kt,
		elective: elective,
		degree:degree
	};

	return (
		<div>
			{/* <div className={classes.flashMessage}>{flash}</div> */}
			<Typography align="center" style={{ textTransform: "uppercase", color: "#a02929", marginTop: "20px" }} color="textSecondary" variant="h4">
				{" "}
				Create Appointment
			</Typography>
			<div className="Select-Options">

				<FormControl style={{width:150}} className={classes.formControl}>
					<InputLabel id="degree-label">Graduation Level</InputLabel>
					<Select labelId="degree-label" value={degree} onChange={degreeChangeHandler} MenuProps={MenuProps}>
						<MenuItem  key={"B.Tech"} value="B.Tech">
							B.Tech
						</MenuItem>
						<MenuItem key={"M.Tech"} value="M.Tech">
							M.Tech
						</MenuItem>
					</Select>
				</FormControl>

				<FormControl className={classes.formControl}>
					<InputLabel id="season-label">Season</InputLabel>
					<Select labelId="season-label" value={season} onChange={seasonChangeHandler} MenuProps={MenuProps}>
						<MenuItem key={"Summer"} value="Summer">
							First Half (Summer)
						</MenuItem>
						<MenuItem key={"Winter"} value="Winter">
							Second Half (Winter)
						</MenuItem>
						<MenuItem key={"June/July"} value="June/July">
							June/July
						</MenuItem>
						<MenuItem key={"December/January"} value="December/January">
							December/January
						</MenuItem>
					</Select>
				</FormControl>

				

				<FormControl className={classes.formControl}>
					<div style={{ color: "rgba(0, 0, 0, 0.54)" }}>Year</div>
					<DatePicker onChange={setDate} value={date} maxDetail={"decade"} format={"yyyy"} clearIcon={null} />
					{/* <Datetime id='year-label' initialValue={String(year)} dateFormat="YYYY" 
                    onChange={(date) => setYear(date.year())}
          /> */}
				</FormControl>

				<FormControl className={classes.formControl}>
					<InputLabel id="semester-label">Semester</InputLabel>
					<Select labelId="semester-label" value={semester} onChange={semesterChangeHandler} MenuProps={MenuProps}>
						{semsArr.map((sem) => (
							<MenuItem key={sem} value={sem}>
								{sem}
							</MenuItem>
						))}
					</Select>
				</FormControl>

				<FormControl className={`${classes.formControl} ${disableDept ? "show-tooltip" : ""}`}>
					<InputLabel id="department-label">Programme</InputLabel>
					<Select labelId="department-label" value={department} onChange={departmentChangeHandler} disabled={disableDept}>
						{departmentArr.map((dept) => (
							<MenuItem key={dept._id} value={dept._id}>
								{dept.Dept_Name}
							</MenuItem>
						))}
					</Select>
				</FormControl>

				<FormControl className={`${classes.formControl} ${disableDept ? "show-tooltip" : ""}`}>
					<InputLabel id="scheme-label">Scheme</InputLabel>
					<Select labelId="scheme-label" value={scheme} onChange={schemeChangeHandler} disabled={disableDept}>
					<MenuItem value={"I"}>I</MenuItem>
					<MenuItem value={"II"}>II</MenuItem>
					<MenuItem value={"II-B"}>II-B</MenuItem>
					<MenuItem value={"III"}>III</MenuItem>
					</Select>
				</FormControl>

				<FormControl className={`${classes.formControl} ${disableSubs ? "show-tooltip-subs" : ""}`}>
					<InputLabel id="subject-label">Subject</InputLabel>
					<Select labelId="subject-label" value={subject} onChange={subjectChangeHandler} MenuProps={MenuProps} disabled={disableSubs}>
						{subjectArr.length > 0 ? (
							subjectArr.map((subject) => (
								<MenuItem key={subject.code + subject._id} value={subject.code}>
									{subject.code+" - "+subject.name}
								</MenuItem>
							))
						) : (
							<MenuItem key="none subs" disabled>
								No Subjects Found
							</MenuItem>
						)}
					</Select>
				</FormControl>
			</div>

			<div>
				<div className="OptionContainer" style={{}}>
					<div>
						<button className="BtnToggle">
							Select Student Type <DoubleArrowIcon />{" "}
						</button>
						<RadioGroup className={classes.reg_kt} value={reg_kt} onChange={(e) => setReg_kt(e.target.value)}>
							<FormControlLabel value="Regular" control={<RedRadio />} label="Regular" />
							<FormControlLabel value="KT" control={<RedRadio />} label="KT" />
							<FormControlLabel value="Supplementary" control={<RedRadio />} label="Supplementary" />
						</RadioGroup>
					</div>
					<div>
						<button className="BtnToggle">
							Select Elective Type <DoubleArrowIcon />{" "}
						</button>
						<RadioGroup className={classes.reg_kt} value={elective} onChange={(e) => setElective(e.target.value)}>
							<FormControlLabel value="Regular" control={<RedRadio />} label="Regular" />
							<FormControlLabel value="DLOC" control={<RedRadio />} label="DLOC" />
							<FormControlLabel value="ILOC" control={<RedRadio />} label="ILOC" />
						</RadioGroup>
					</div>
				</div>

				<div className="OptionContainer">
					<button className="BtnToggle">
						Select Exam Type <DoubleArrowIcon />{" "}
					</button>
					<RadioGroup className={classes.reg_kt} value={examType} onChange={(e) => setExamType(e.target.value)}>
						{examTypes.map((type, index) => (
							<FormControlLabel key={"examtype" + index} value={type.Exam_Name} control={<RedRadio key={"radio" + index} />} label={type.Exam_Name} />
						))}
					</RadioGroup>
					{/* onChange={examTypeHandler} */}
				</div>
			</div>

			<AppointmentTable
				options={options}
				validate={isAllFieldsFilled}
				// setFlash={setFlash}
			/>
		</div>
	);
}

export default Appointment;
