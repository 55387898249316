import React from 'react'
import './Footer.css'
import footerLogo from '../../Assets/images/footerlogo1.jpg'
import LanguageIcon from '@material-ui/icons/Language';
import FacebookIcon from '@material-ui/icons/Facebook';
import InstagramIcon from '@material-ui/icons/Instagram';

function Footer() {
  return (
    <div className="footer">
      <div className="col-container">
        <div className="left-col">
          <img className="footer-logo" src={footerLogo} alt="logo-footer" />
        </div>
        <div className="right-col">
          <h6 style={{ fontSize: '1rem' }}>Developed & Maintained By:</h6>
          <h6 style={{ color: "#911717" }}>
          Team - Examiner Appointment System
          </h6>
          <p className="right-col-para">
            K. J. Somaiya Institute of Technology, Sion, Mumbai
          </p>
          <a href="https://kjsieit.somaiya.edu/en/programme/information-technology-engineering"><LanguageIcon style={{ color: "#911717", marginRight: '.5rem' }} /></a>
          <a href="https://www.facebook.com/KJSIEITITDEPT"><FacebookIcon style={{ color: "#911717", marginRight: '.5rem' }} /></a>
          <a href="https://www.instagram.com/it_kjsieit/"><InstagramIcon style={{ color: "#911717" }} /></a>
          <p>
            For queries write to &nbsp;
            <a href="mailto:kjsieit.eas@somaiya.edu" style={{ color: "#911717", textDecoration: 'none' }}>kjsieit.eas@somaiya.edu</a>
          </p>
        </div>
      </div>
    </div>
  )
}

export default Footer
