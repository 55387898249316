import React, { useState, useEffect } from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import EventOutlinedIcon from '@material-ui/icons/EventOutlined';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import Grid from '@material-ui/core/Grid';
import DescriptionIcon from '@material-ui/icons/Description';
import RecentActorsIcon from '@material-ui/icons/RecentActors';
import MenuBookIcon from '@material-ui/icons/MenuBook';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import { Loading } from '../Loading';
import { Link } from 'react-router-dom'
import { useStyles } from './dashboard_styles'


const Dashboard = (props) => {
  const classes = useStyles();

  useEffect(() => {

  }, [])


  if (!true) {
    return <Loading />
  }
  return (
    <div >
      <div className={classes.root}>
        {/* Grid */}

        
        <Grid className={classes.grid} container spacing={3}>
          
          <Grid item xs={4}>
            <Card className={classes.card}>
              <CardContent >
                <Typography className={classes.wrapIcon} variant="h6" component="h3">
                  Manage Users <button className={classes.storeIcon}><PersonAddIcon style={{ color: "white" }} /></button>
                </Typography>

                <Typography style={{ width: "230px" }} variant="body2" component="p">
                  This page enables CRUD operations on users.
                  You can even change roles.
                </Typography>
              </CardContent>
              <div>
                <Link to='/adduser' style={{ color: "#e0e0e0" }}>
                  {props.role === 'Admin'?
                  <Button className={classes.go_btn} color="primary" variant="outlined">GO  </Button>
                :
                  <Button disabled={true} className={classes.go_btn} style={{width:150}} color="primary" variant="outlined">Admins Only</Button>
                }
                </Link>
              </div>
            </Card>
          </Grid>

          <Grid item xs={4}>
            <Card className={classes.card}>
              <CardContent >
                <Typography className={classes.wrapIcon} variant="h6" component="h3">
                  Manage Departments <button className={classes.storeIcon}><AssignmentIndIcon style={{ color: "white" }} /></button>
                </Typography>

                <Typography style={{ width: "240px" }} variant="body2" component="p">
                This page enables viewing, addition and deletion of departments. You can even search them.  
                </Typography>

              </CardContent>
              <div>
                <Link to='/adddepartment' style={{ color: "#e0e0e0" }}>
                {props.role === 'Admin'?
                  <Button className={classes.go_btn} color="primary" variant="outlined">GO  </Button>
                :
                  <Button disabled={true} className={classes.go_btn} style={{width:150}} color="primary" variant="outlined">Admins Only</Button>
                }
                </Link>
              </div>
            </Card>
          </Grid>

          <Grid item xs={4}>
            <Card className={classes.card}>
              <CardContent >
                <Typography className={classes.wrapIcon} variant="h6" component="h3">
                  Manage Subjects <button className={classes.storeIcon}><MenuBookIcon style={{ color: "white" }} /></button>
                </Typography>

                <Typography style={{ width: "223px" }} variant="body2" component="p">
                  This page enables viewing, addition and deletion of subjects.
                  You can even search or filter them.
                </Typography>
              </CardContent>
              <div>
                <Link to='/addsubject' style={{ color: "#e0e0e0" }}>
                  <Button className={classes.go_btn} color="primary" variant="outlined">GO  </Button>
                </Link>
              </div>
            </Card>
          </Grid>
          
          <Grid item xs={4}>
            <Card className={classes.card}>
              <CardContent >
                <Typography className={classes.wrapIcon} variant="h6" component="h3">
                  Manage Examiners <button className={classes.storeIcon}><RecentActorsIcon style={{ color: "white" }} /></button>
                </Typography>

                <Typography style={{ width: "230px" }} variant="body2" component="p">
                  This page enables CRUD operations on examiners.
                  You can even search or filter them.


                </Typography>
              </CardContent>
              <div>
                <Link to='/examiners' style={{ color: "#e0e0e0" }}>
                  <Button className={classes.go_btn} color="primary" variant="outlined">GO  </Button>
                </Link>
              </div>
            </Card>
          </Grid>

          <Grid item xs={4}>
            <Card className={classes.card}>
              <CardContent >
                <Typography className={classes.wrapIcon} variant="h6" component="h3">
                  Make Appointment <button className={classes.storeIcon}><EventOutlinedIcon style={{ color: "white" }} /></button>
                </Typography>

                <Typography style={{ width: "200px" }} variant="body2" component="p">
                  Here you can filter and make appointments for all the examiners.
                </Typography>
              </CardContent>
              <div>
                <Link to='/appointment' style={{ color: "#e0e0e0" }}>
                  <Button className={classes.go_btn} variant="outlined">GO  </Button>
                </Link>
              </div>
            </Card>
          </Grid>

          <Grid item xs={4}>
            <Card className={classes.card}>
              <CardContent >
                <Typography className={classes.wrapIcon} variant="h6" component="h3">
                  Appointment Summary<button className={classes.storeIcon}><DescriptionIcon style={{ color: "white" }} /></button>
                </Typography>

                <Typography style={{ width: "230px" }} variant="body2" component="p">
                  Download filtered marked appointments as pdf or csv
                </Typography>
              </CardContent>
              <div>
                <Link to='summary' style={{ color: "#e0e0e0" }}>
                  <Button className={classes.go_btn} color="primary" variant="outlined">GO  </Button>
                </Link>
              </div>
            </Card>
          </Grid>
        </Grid>
      </div>
      {/* {props.role === 'Admin' ?
        <Grid className={classes.grid} container spacing={3}>
          

          
          

        </Grid>
        : null} */}


    </div>
  );
}

export default Dashboard