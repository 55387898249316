import logo from '../../../Assets/images/Somaiya Logo.png'
import './Landing.css'

function Landing() {
  return (
    <div className="landing">
      <div className="top-container">

        <div className="landing-logo">
          <img className="landing-logo-img" src={logo} alt="logo" />
        </div>
        <div className="college-title">
          <h2 className="college-title-h2">K. J. Somaiya Institute of Technology, Sion, Mumbai</h2>
          <div>An Autonomous Institute Permanently Affiliated to the University of Mumbai</div>
          <div>Accredited with 'A' Grade by NAAC (3.21 CGPA), Approved by AICTE, New Delhi
          </div>
        </div>
      </div>
      <div className="bottom-container">
        <h1 className="project-title">
          Examiner Appointment System <br />
        </h1>
        <h5 className="developed-by">
          Developed by Team - Examiner Appointment System
        </h5>
      </div>
    </div>
  )
}

export default Landing
