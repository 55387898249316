import React, { Component } from 'react'
import * as classes from './Login.module.css'
import { Button } from 'react-bootstrap'
// import { TransitionInput } from '../utility/FormTransition'
import somaiyaLogo from '../../Assets/images/footerlogo1.jpg'
import axios from 'axios'
import { baseUrl } from '../../redux/baseUrl'
class ResetPassword extends Component {

  constructor(props) {
    super(props)

    this.state = {
      password: '',
      token: '',
      error: null
    }
  }

  componentDidMount() {
    // const input = document.querySelectorAll('input')
    // TransitionInput(input)
    this.setState({ token: this.props.history.location.pathname.split("/")[2] })
  }


  passwordChangeHandler = (e) => {
    this.setState(prevState => ({
      ...prevState,
      password: e.target.value
    }))
  }

  onSubmitHandler = (e) => {
    e.preventDefault()

    const url = baseUrl+"/user/resetPassword/" + this.state.token

    axios.post(url, { newPassword: this.state.password })
      .then(res => {

        this.props.history.push('/login')
      })
      .catch(err => {

        this.setState(prevState => {
          return {
            ...prevState,
            error: err.response.data.password
          }
        })
      })
  }



  render() {
    return (
      <div className={classes.Login}>

        {/* <span className="text-muted mt-2">()</span> */}

        <div className={`${classes.ForgotFormCont} ${classes.LoginForm}`}>
          <img className={classes.LoginLogo} src={somaiyaLogo} alt="somaiya-logo" />
          <h4>
            Reset Password
          </h4>
          <form onSubmit={this.onSubmitHandler}>
            <div className={classes.password}>
              <label htmlFor="password" style={{ fontWeight: "600", flex: "0 0 83.33333333%" }}>New Password</label>
              <input className={`${classes.LoginInput} ${this.state.error ? classes.invalid : ''} `} type="password" name="password" onChange={this.passwordChangeHandler} value={this.state.password} required />
              {this.state.error && <div className={classes.validationMsg}>{this.state.error.map(err => (<div>{err}</div>))}</div>}

            </div>
            <div className={classes.ForgotbtnCont}>
              <Button className={classes.LoginBtn} type="submit">Submit</Button>
            </div>
          </form>
        </div>
      </div>

    )
  }
}

export default ResetPassword
