import React, { Component } from 'react';

import { Switch, Route, Redirect, withRouter } from 'react-router-dom'
import { connect } from 'react-redux';
import { LoginUser, LogoutUser, checkStatus } from '../redux/ActionCreators/AuthCreator';

import Login from './Auth/Login';
import ForgotPassword from './Auth/ForgotPassword'
import ResetPassword from './Auth/ResetPassword'
import Appointment from './Appointment/Appointment'
import addDepartment from './addDepartment/addDepartment'
import { Loading } from './Loading';
// import AppointmentTable from './Appointment/AppointmentTable';
import Navbar from './Navbar/Navbar';
import PageNotFound from './PageNotFound/PageNotFound';
import Examiners from './Examiners/Examiners';
import AddUser from './addUser/AddUser';
import AddSubject from './addSubject/AddSubject'
import Landing from './Home/LandingPage/Landing';
import Footer from './footer/Footer';
import Dashboard from './Dashboard/Dashboard'
import AboutUs from './AboutUs/AboutUs';
import Summary from './Summary/Summary';


const mapStateToProps = state => {
    return {
        Auth: state.Auth,
    }
}

const mapDispatchToProps = (dispatch) => ({
    LoginUser: (creds) => dispatch(LoginUser(creds)),
    LogoutUser: () => dispatch(LogoutUser()),
    checkStatus: () => dispatch(checkStatus()),
})

// var current_location = ""
class Main extends Component {

    componentDidMount() {

        this.props.checkStatus()
    }
    render() {
        if (this.props.Auth.checkingStatus == false) {
            const AdminRoute = ({ ...props }) => {
                const isAdmin = this.props.Auth.role === "Admin"
                const isAllowed = this.props.Auth.isAuthenticated
                const isAccountant = this.props.Auth.role == 'Accounts Department'

                return isAdmin
                    ? (<Route {...props} />)
                    : isAllowed ? (isAccountant && props.path !== '/summary' ? <Redirect to="/summary" /> : <Redirect to="/dashboard" />)
                        : (<Redirect to="/login" />)

            };

            const PrivateRoute = ({ ...props }) => {
                const isAllowed = this.props.Auth.isAuthenticated
                const isAccountant = this.props.Auth.role === 'Accounts Department'

                return isAllowed
                    ? (isAccountant && props.path !== '/summary' ? <Redirect to="/summary" /> : <Route {...props} />)
                    : (<Redirect to="/login" />)
            };


            const PublicRoute = ({ ...props }) => {
                const isAllowed = this.props.Auth.isAuthenticated
                const isAccountant = this.props.Auth.role == 'Accounts Department'
                return isAllowed
                    ? (isAccountant && props.path !== '/summary' ? <Redirect to="/summary" /> : <Redirect to='/dashboard' />)
                    : (<Route {...props} />)
            };

            return (
                <div>
                    <Navbar LogoutUser={this.props.LogoutUser} role={this.props.Auth.role} isAuthenticated={this.props.Auth.isAuthenticated} />

                    <Switch>
                        <PrivateRoute exact path="/appointment" component={Appointment} />
                        {/* <PrivateRoute exact path="/history" component={HistoryFilter} /> */}
                        <PrivateRoute exact path="/summary" component={Summary} />
                        <PrivateRoute exact path="/examiners" component={Examiners} />
                        <PrivateRoute exact path='/dashboard' component={() => <Dashboard role={this.props.Auth.role} />}></PrivateRoute>
                        <PrivateRoute exact path="/addsubject" component={AddSubject} />

                        {/* <PrivateRoute exact path='/manual' component={Manual}></PrivateRoute> */}

                        <AdminRoute exact path="/adddepartment" component={addDepartment} />
                        <AdminRoute exact path="/adduser" component={AddUser} />
                        {/* <PrivateRoute exact path="/dashboard" component={() => <Dashboard LogoutUser={this.props.LogoutUser} />} /> */}

                        <PublicRoute exact path="/forgotPassword" component={ForgotPassword} />
                        <PublicRoute exact path="/resetPassword/:id" component={ResetPassword} />
                        <PublicRoute exact path="/login" component={() => <Login Auth={this.props.Auth} LoginUser={this.props.LoginUser} />} />
                        <PublicRoute exact path="/home" component={Landing} />
                        <PublicRoute exact path="/about-us" component={AboutUs} />

                        <Route exact path="/" >
                            {/* <Redirect to="/login" /> */}
                            <Redirect to="/home" />
                        </Route>

                        <Route component={PageNotFound} />

                        {/* <Route path="/appointmenttable" component={AppointmentTable} /> */}
                    </Switch>
                    <Footer />
                </div>
            )
        }
        else {

            return (
                <Loading />
            )
        }
    }
}


export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Main));
