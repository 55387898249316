import React, { Component } from 'react'
import { Form, Button } from 'react-bootstrap'
import * as classes from './Login.module.css'
import { Link } from 'react-router-dom'
import axios from 'axios'
import somaiyaLogo from '../../Assets/images/footerlogo1.jpg'
import { baseUrl } from '../../redux/baseUrl';

class ForgotPassword extends Component {

  constructor(props) {
    super(props)

    this.state = {
      email: '',
      submitted: false,
      error: null
    }
  }




  emailChangeHandler = (e) => {
    this.setState(prevState => (
      {
        ...prevState,
        email: e.target.value
      }
    ))
  }

  onSubmitHandler = (e) => {
    e.preventDefault()
    axios.post(baseUrl+'/user/forgotPassword', { email: this.state.email })
      .then(res => {

        this.setState({ submitted: true })
        // history.push('/dashboard')
      })
      .catch(err => {

        this.setState(prevState => ({ ...prevState, error: err.response.data.email }))
      })
  }


  render() {

    let showUI
    showUI = (
      <div className={classes.Login}>


        <div className={`${classes.ForgotFormCont} ${classes.LoginForm}`}>
          <img className={classes.LoginLogo} src={somaiyaLogo} alt="somaiya-logo" />
          <h4>Set/Change Password</h4>
          <form onSubmit={this.onSubmitHandler}>
            <div className={classes.email}>
              <label style={{ fontWeight: "600", flex: "0 0 83.33333333%" }} htmlFor="email">Enter Your Registered Email</label>
              <input className={`${classes.ForgetInput} ${this.state.error ? classes.invalid : ''}`} type="email" name="email" onChange={this.emailChangeHandler} value={this.state.email}
                placeholder="Enter your somaiya mail id" />
              {this.state.error && <div className={classes.validationMsg}>For confidentiality purpose, you aren't allowed to login to the portal.</div>}
            </div>
            <div className={classes.ForgotbtnCont}>
              <Button className={classes.LoginBtn} type="submit">Submit</Button>
            </div>
          </form>
        </div>
      </div>
    )

    if (this.state.submitted) {
      showUI = (
        <div className="container text-center">
          <h1 className='text-center m-4'>A reset link has been sent to the entered email</h1>
          <Link to="/login"><Button className={classes.LoginBtn}>Go to Login Page</Button></Link>
          <br />
          <span className="text-muted">(if you did not receive the reset link via email then the entered email is not registered in our server :\ )</span>
        </div>
      )
    }

    return <div>{showUI}</div>
  }
}

export default ForgotPassword
