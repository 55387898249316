import { createStore, combineReducers, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
// import logger from 'redux-logger';
import Auth from './Reducers/AuthReducer';

export const ConfigureStore = () => {
    const store = createStore(
        //Reducers Here
        combineReducers({ 
            Auth : Auth
        }),
        applyMiddleware(thunk)
    )

    return store;
}