import React, { useCallback, useEffect } from 'react'
import { Link } from 'react-router-dom'
import AddCommentRoundedIcon from '@material-ui/icons/AddCommentRounded';
import HistoryRoundedIcon from '@material-ui/icons/HistoryRounded';
import ExitToAppRoundedIcon from '@material-ui/icons/ExitToAppRounded';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import PersonAddIcon from '@material-ui/icons/PersonAdd';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';

import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import { makeStyles } from '@material-ui/core/styles';
import SwipeableDrawer from '@material-ui/core/SwipeableDrawer';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Typography from '@material-ui/core/Typography';
import axios from 'axios'
import PersonIcon from '@material-ui/icons/Person';
import DashboardIcon from '@material-ui/icons/Dashboard';
import GetAppIcon from '@material-ui/icons/GetApp';
// import logo from '../../Assets/images/logo1.png'
import logo from '../../Assets/images/headerlogo.png'
import { withRouter } from 'react-router';
import Button from '@material-ui/core/Button'

import { baseUrl } from '../../redux/baseUrl';

import './Navbar.css'

const useStyles = makeStyles({
  list: {
    width: 270,
  },
  sidebarHeader: {
    textAlign: "center",
    backgroundColor: "#911717",
    color: "white",
    fontSize: 16,
    height: 70,
    display: 'flex',
    justifyContent: 'start',
    alignItems: 'center',
    padding:5,
  }
});


function Navbar(props) {
  const classes = useStyles();
  const [drawerOpen, setDrawerOpen] = React.useState(false)
  const [Open, setOpen] = React.useState(false)
  const [currentUser, setCurrentUser] = React.useState("")

  useEffect(() => {
    axios.get(baseUrl+'/user/checkstatus', { withCredentials: true })
      .then(res => {
        setCurrentUser(res.data.user)
      })

  }, [props.isAuthenticated])

  function logout() {
    toggleDrawer(false)
    props.LogoutUser()
  }

  const list = (
    <div
      className={classes.list}
      role="presentation"
    >
      <Typography className={classes.sidebarHeader} variant="h4">
        {currentUser?<>
      <PersonIcon style={{ color: '#fff',fontSize:30,margin:'auto 20' }} /><span style={{flexGrow:0.5 ,textAlign:"center"}}>{currentUser.first_name} {currentUser.last_name}  <br /> ({currentUser.role})</span>
      </>
      :null}
      </Typography>
      <List >
        <MenuItem onClick={() => toggleDrawer(false)} component={Link} to="/dashboard">
          <ListItem >
            <ListItemIcon><DashboardIcon /></ListItemIcon>
            <ListItemText primary={"Dashboard"} />
          </ListItem>
        </MenuItem>
        <MenuItem onClick={() => toggleDrawer(false)} component={Link} to="/appointment">
          <ListItem>
            <ListItemIcon><AddCommentRoundedIcon /></ListItemIcon>
            <ListItemText primary={"Appoint Examiner"} />
          </ListItem>
        </MenuItem>
        <MenuItem onClick={() => toggleDrawer(false)} component={Link} to="/summary">
          <ListItem >
            <ListItemIcon><GetAppIcon /></ListItemIcon>
            <ListItemText primary={"Get Report"} />
          </ListItem>
        </MenuItem>
        <MenuItem onClick={() => toggleDrawer(false)} component={Link} to="/examiners">
          <ListItem>
            <ListItemIcon><SupervisorAccountIcon /></ListItemIcon>
            <ListItemText primary={"Manage Examiners"} />
          </ListItem>
        </MenuItem>
        <MenuItem onClick={() => toggleDrawer(false)} component={Link} to="/addsubject">
          <ListItem>
            <ListItemIcon><LibraryBooksIcon /></ListItemIcon>
            <ListItemText primary={"Manage Subjects"} />
          </ListItem>
        </MenuItem>
      </List>
      {props.role === "Admin" ?
        <>
          <Divider style={{ borderTop: "1px solid #222" }} />
          <List>
            <MenuItem onClick={() => toggleDrawer(false)} component={Link} to="/adddepartment">
              <ListItem>
                <ListItemIcon><LibraryBooksIcon /></ListItemIcon>
                <ListItemText primary={"Manage Departments"} />
              </ListItem>
            </MenuItem>
            <MenuItem onClick={() => toggleDrawer(false)} component={Link} to="/adduser">
              <ListItem>
                <ListItemIcon><PersonAddIcon /></ListItemIcon>
                <ListItemText primary={"Manage Users"} />
              </ListItem>
            </MenuItem>
          </List>
        </>
        : null}
      <MenuItem onClick={logout} >
        <ListItem className='logout'>
          <ListItemIcon><ExitToAppRoundedIcon style={{ color: '#a02929' }} /></ListItemIcon>
          <ListItemText primary={"Logout"} />
        </ListItem>
      </MenuItem>
    </div>
  );


  // let ulLis = null
  // if (props.isAuthenticated) {
  //   ulLis = (
  //     <ul>
  //       <li><div className="logout" onClick={props.LogoutUser}>Logout <ExitToAppRoundedIcon /></div></li>
  //     </ul>
  //   )
  // }

  function toggleDrawer(val) {
    setDrawerOpen(val)
  }


  return (
    <nav className={Open ? "nav-active" : ''}>
      {props.isAuthenticated && props.role !== "Accounts Department" ?
        <IconButton style={{ color: "white", cursor: "pointer" }} onClick={() => toggleDrawer(true)}><MenuIcon /></IconButton>

        : (props.role === "Accounts Department") ? <>
          <Typography className={classes.sidebarHeader} variant="h4">
            <PersonIcon style={{ color: '#fff' }} /> &nbsp;{currentUser.first_name} {currentUser.last_name} ({currentUser.role}) 
            <Button onClick={logout} style={{ marginLeft: 10, color: '#931717' }} variant="contained">Logout<ExitToAppRoundedIcon /></Button>
          </Typography>

        </>
          : <div>
            {props.history.location.pathname === '/home' ?
              <Link className="landing-link" to="/about-us">
                About Us
              </Link>
              :
              <Link className="landing-link" to="/home">
                Home
              </Link>
            }
            <Link className="landing-link" to="/login">
              Login
            </Link>
          </div>}
      <Link className={props.isAuthenticated ? "logo" : "hide"} style={{ marginLeft: '100px' }} to="/dashboard">
        <img src={logo} alt="Logo Image" style={{ height: '70px', position: 'relative', zIndex: '100', background: '#fff', padding: 10, left: '70px' }} />
      </Link>
      <SwipeableDrawer
        anchor="left"
        open={drawerOpen}
        onClose={() => toggleDrawer(false)}
        onOpen={() => toggleDrawer(true)}
      >
        {list}
      </SwipeableDrawer>
      {/* {ulLis} */}
      {/* <div className="burger" onClick={toggleClick}>
        <div className="line line1"></div>
        <div className="line line2"></div>
        <div className="line line3"></div>
      </div> */}
    </nav>
  )
}

export default withRouter(Navbar)
