import React, { Component } from 'react'
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import './AddUser.css'
import axios from 'axios'

import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Backdrop from '../Backdrop/Backdrop';
import { Typography, Select, MenuItem, MenuProps } from '@material-ui/core';
import { baseUrl } from '../../redux/baseUrl';

import { Loading } from './../Loading';
import VerifiedUserIcon from '@material-ui/icons/VerifiedUser';

const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#a02929",
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

class AddUser extends Component {

  constructor(props) {
    super(props)

    this.state = {
      users: [],
      first_name: "",
      last_name: "",
      email: "",
      password: "",
      role: "Examination Cell",
      showModal: false,
      isLoading: true,
      currentUser: ""
    }

  }

  componentDidMount() {
    this.getUsers()

    axios.get(baseUrl+'/user/checkstatus', { withCredentials: true })
      .then(res => {
        this.setState({ currentUser: res.data.user })
      })
  }


  getUsers = () => {
    axios.get(baseUrl+'/user',{withCredentials:true})
      .then(res => {

        this.setState({ users: [...res.data] })
        this.setState({ isLoading: false })
      })
      .catch(err => {

      })
  }

  inputChangeHandler = (e) => {
    this.setState({ [e.target.name]: e.target.value })
  }

  submitHandler = (e) => {
    e.preventDefault()
    const data = {
      first_name: this.state.first_name,
      last_name: this.state.last_name,
      email: this.state.email,
      role: this.state.role
    }
    axios.post(baseUrl+'/user', { newUsers: data },{withCredentials:true})
      .then(res => {
        alert("User Successfully Added")
        this.setState(prevState => {

          return {
            first_name: '',
            last_name: '',
            email: '',
            password: '',
            role: 'Examination Cell',
            showModal: false
          }
        })
        this.getUsers()
      })
      .catch(err => console.log(err))

  }

  ToggleModalHandler = (e) => {
    this.setState(prevState => { return { showModal: !prevState.showModal } })
  }

  closeModalHandler = () => {
    this.setState({ showModal: false })
  }

  deleteUserHandler = (e, id) => {
    const users = [id]
    if (window.confirm('Are you sure you want to delete this user?')) {
      axios.post(baseUrl+'/user/delete/', { data: { users: users } },{withCredentials:true})
        .then(res => {
          this.getUsers()
        })
        .catch(err => {
          console.log(err)
        })
    }
  }

  changeRole = (e, id) => {
    if (window.confirm('Are you sure to change the role of this user?')) {
      axios.put(baseUrl+'/user/changerole/' + id, { role: e.target.value },{withCredentials:true})
        .then(res => {

          this.getUsers()
          this.setState({ role: e.target.value })
        })
        .catch(err => {
          console.log(err)
        })
    }
  }
  render() {

    if (this.state.isLoading) {
      return <Loading />
    }

    return (
      <div className="main-container" style={{ margin: '20px 0' }}>
        <Typography align="center" style={{ fontStyle: "Courier,Arial,sans-shreiff,TimesNewRoman", color: "#a02929", textTransform: 'uppercase' }} color="textSecondary" variant="h4">
          Manage Users</Typography>

        <div style={{ width: '95%', margin: 'auto' }}>
          <Button variant="contained" color="primary" onClick={this.ToggleModalHandler} style={{ marginLeft: 'auto', display: 'block', color: "#a02929", backgroundColor: "#fff", border: "0.2px solid #a02929" }}>Create New User</Button>
        </div>
        <TableContainer component={Paper} style={{ width: '95%', margin: '15px auto' }}>
          <Table aria-label="customized table">
            <TableHead>
              <TableRow>
                <StyledTableCell>Full Name</StyledTableCell>
                <StyledTableCell>Email</StyledTableCell>
                {/* <StyledTableCell >Role</StyledTableCell> */}
                <StyledTableCell >Update Role</StyledTableCell>
                <StyledTableCell >Delete User</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {this.state.users.map((user) => (
                <StyledTableRow key={user._id} className="one-user">
                  <StyledTableCell>{user.first_name} {user.last_name}</StyledTableCell>
                  <StyledTableCell>{user.email}</StyledTableCell>
                  {/* <StyledTableCell>{user.role}</StyledTableCell> */}
                  <StyledTableCell>

                    <Select
                      value={user.role}
                      onChange={(e) => this.changeRole(e, user._id)}
                      // MenuProps={MenuProps}
                      disabled={user._id === this.state.currentUser.id}
                      defaultValue={user.role}

                    >
                      <MenuItem key={'Admin'} value="Admin">
                        Admin
                      </MenuItem>
                      <MenuItem key={'Examination Cell'} value="Examination Cell">
                        Examination Cell
                      </MenuItem>
                      <MenuItem key={'Accounts Department'} value="Accounts Department">
                        Accounts Department
                      </MenuItem>
                    </Select>
                  </StyledTableCell>
                  <StyledTableCell>{(user._id === this.state.currentUser.id) ? <div> &nbsp;&nbsp;Current User <VerifiedUserIcon fontSize="small" /></div> :
                    <Button style={{ backgroundColor: "#a02929", color: "#fff" }} onClick={(e) => { this.deleteUserHandler(e, user._id) }} variant="contained" color="secondary">Delete User</Button>}
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>


        <div className="form-container" style={{ transform: this.state.showModal ? `translateY(0) translateX(-50%)` : `translateY(-100vh) translateX(-50%)`, opacity: this.state.showModal ? `1` : `0` }}>

          <form onSubmit={this.submitHandler} >
            <TextField required id="standard-required" label="First Name" name="first_name" onChange={this.inputChangeHandler} value={this.state.first_name} />
            <TextField required id="standard-required" label="Last Name" type="text" name="last_name" onChange={this.inputChangeHandler} value={this.state.last_name} />
            <TextField required id="standard-required" label="Email" type="email" name="email" onChange={this.inputChangeHandler} value={this.state.email} />
            <Select
              value={this.state.role}
              onChange={(e) => this.setState({ role: e.target.value })}
              defaultValue={'Examination Cell'}
            >
              <MenuItem key={'Admin'} value="Admin">
                Admin
              </MenuItem>
              <MenuItem key={'Examination Cell'} value="Examination Cell">
                Examination Cell
              </MenuItem>
              <MenuItem key={'Accounts Department'} value="Accounts Department">
                Accounts Department
              </MenuItem>
            </Select>
            <Button variant="contained" style={{ backgroundColor: "#a02929", color: "white" }} type="submit">Add User</Button>
          </form>
        </div>

        <Backdrop show={this.state.showModal} clicked={this.closeModalHandler} />
      </div>
    )
  }
}

export default AddUser
