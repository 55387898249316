import React, { useEffect } from "react";
import TextField from "@material-ui/core/TextField";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import MenuItem from "@material-ui/core/MenuItem";
import {
  Table,
  TableBody,
  TableHead,
  TableRow,
  TableCell,
  TableContainer,
  Paper,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";

import "./AddExaminers.css";
import sample from "../../Assets/samples/Format Panel of Examiners.csv";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: "25ch",
      borderColor: "white!important",
    },
  },
  error: {
    color: "red",
    width: "80%",
    fontSize: 14.5,
  },
}));
const ErrorTextField = withStyles({
  root: {
    "& .MuiInput-underline:before": {
      borderBottomColor: "red", // Semi-transparent underline
    },
    "& .MuiInput-underline:hover:before": {
      borderBottomColor: "red", // Solid underline on hover
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "red", // Solid underline on focus
    },
    "& .MuiFormHelperText-root.Mui-error": {
      fontSize: 14,
      textAlign: "center",
    },
  },
})(TextField);

export default function AddExaminers(props) {
  const classes = useStyles();
  const [subjectOpts, setSubjectOpts] = React.useState([]);
  const [autoValue, setAutoValue] = React.useState([]);

  const options = props.options;

  useEffect(() => {
    setSubjectOpts(props.options.filteredSubjects);
    setAutoValue([]);
  }, [props.options.filteredSubjects]);

  // function handleChangeGender(e){
  //   options.setGender(e.target.value)
  // }

  function handleChangeExaminerType(e) {
    options.setExaminerType(e.target.value);
  }

  function handleChangeDepartment(e) {
    options.setDept(e.target.value);
    setAutoValue([]);
  }

  function handleSubjectInput(e, val) {
    options.setInfo({ ...options.info, subjectData: val });
    setAutoValue(val);
    const filteredArray = options.filteredSubjects.filter(
      (element) => !val.includes(element)
    );
    setSubjectOpts(filteredArray);
  }

  function handleInput(e) {
    options.setErrMsg({
      ...options.errMsg,
      allFieldsFilled: "",
      uploadCorrect: "",
    });
    if (e.target.files) {
      options.setInfo({ ...options.info, file: e.target.files });
      options.setDisabled(e.target.files.length > 0 ? true : false);
    } else {
      let name = e.target.name;
      options.setInfo({ ...options.info, [name]: e.target.value, file: "" });
    }
  }
  return (
    <form className={classes.root}>
      <div align="center" className={classes.error}>
        {options.errMsg.allFieldsFilled}
      </div>
      <div>
        <TextField
          onChange={handleInput}
          disabled={options.disabled}
          value={options.info.first_name ? options.info.first_name : ""}
          required
          label="First Name"
          name="first_name"
        ></TextField>

        <TextField
          disabled={options.disabled}
          onChange={handleInput}
          required
          label="Last Name"
          name="last_name"
          value={options.info.last_name ? options.info.last_name : ""}
        ></TextField>
      </div>
      <div>
        <TextField
          required
          error={options.errMsg.emailCorrect}
          disabled={options.disabled}
          name="email"
          type="email"
          label="Email"
          value={options.info.email ? options.info.email : ""}
          onChange={handleInput}
          style={{ width: "25ch" }}
          helperText={options.errMsg.emailCorrect}
        ></TextField>
        <TextField
          required
          error={options.errMsg.contactCorrect}
          disabled={options.disabled}
          type="number"
          value={options.info.contact ? options.info.contact : ""}
          name="contact"
          label="Mobile Number"
          onChange={handleInput}
          style={{ width: "25ch" }}
          helperText={options.errMsg.contactCorrect}
        ></TextField>
      </div>

      <div>
        <TextField
          required
          error={options.errMsg.org}
          name="Organization"
          type="text"
          label="Organization"
          value={options.info.Organization}
          disabled={options.disabled}
          onChange={handleInput}
          style={{ width: "25ch" }}
          helperText={options.errMsg.org}
        ></TextField>
        <TextField
          required
          error={options.errMsg.designation}
          disabled={options.disabled}
          value={options.info.designation}
          name="Designation"
          label="Designation"
          onChange={handleInput}
          style={{ width: "25ch" }}
          helperText={options.errMsg.designation}
        ></TextField>
      </div>

      <div>
        <Autocomplete
          multiple
          id="tags-standard"
          disabled={options.disabled}
          options={subjectOpts}
          value={autoValue}
          getOptionLabel={(option) => option}
          onChange={handleSubjectInput}
          size="small"
          // filterOptions = {(options,state) => options.slice(0,2)}
          renderInput={(params) => (
            <TextField
              {...params}
              style={{ width: "52ch" }}
              variant="standard"
              label="Select Subjects"
              placeholder="Add Multiple Subjects as Tags"
            />
          )}
        />
      </div>
      <div style={{ textAlign: "center" }}>
        {/* <div>
        <TextField 
          disabled = {options.disabled}
          style={{width:"35ch"}}
          select
          name = "gender"
          label = 'Gender' 
          onChange = {handleChangeGender}
          value = {options.gender}
        >
          <MenuItem key = {"Male"} value = {"Male"}>Male</MenuItem>
          <MenuItem key = {"Female"} value = {"Female"}>Female</MenuItem>
        </TextField>
      </div> */}

        <div>
          <TextField
            disabled={options.disabled}
            style={{ width: "35ch" }}
            select
            name="department"
            label="Department"
            onChange={handleChangeDepartment}
            value={options.dept}
          >
            {options.deptArr.map((option) => (
              <MenuItem key={option.Dept_Name} value={option.Dept_Name}>
                {option.Dept_Name}
              </MenuItem>
            ))}
          </TextField>
        </div>

        <div>
          <TextField
            disabled={options.disabled}
            style={{ width: "35ch" }}
            select
            name="type"
            label="Examiner Type"
            onChange={handleChangeExaminerType}
            value={options.examinerType}
          >
            <MenuItem key={"Internal"} value={"Internal"}>
              Internal
            </MenuItem>
            <MenuItem key={"External"} value={"External"}>
              External
            </MenuItem>
          </TextField>
        </div>
        <br />
        <span style={{ color: "rgb(160, 41, 41)", fontWeight: "bold" }}>
          OR
        </span>
        <br />
        <br />
        <div>
          <Button
            variant="contained"
            color="default"
            className={classes.button}
            startIcon={<CloudUploadIcon />}
          >
            <input
              value={options.info.file ? undefined : ""}
              onChange={handleInput}
              name="upload"
              type="file"
              accept=".csv"
            ></input>
            Upload CSV
          </Button>
          <br />
          <br />
          {options.errMsg.uploadCorrect ? (
            Array.isArray(options.errMsg.uploadCorrect) ? (
              <>
                <TableContainer style={{ padding: "30px" }} component={Paper}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell style={{ color: "red" }}>
                          Errors in the file (rest of the data has been
                          inserted)
                        </TableCell>
                        <TableCell style={{ color: "red" }}>Line</TableCell>
                        <h5> </h5>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {options.errMsg.uploadCorrect.map((errMsg, index) => (
                        <TableRow align="left">
                          <TableCell>{errMsg.split("line")[0]}</TableCell>
                          <TableCell>{errMsg.split("line")[1]}</TableCell>
                          {/* <hr/> */}
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </>
            ) : (
              <Paper>
                <span align="left" className={classes.error}>
                  {options.errMsg.uploadCorrect}
                </span>
              </Paper>
            )
          ) : null}
          {/* <WhiteTextField disabled style={{marginTop:-30,width:"80%"}} error={options.errMsg.uploadCorrect}   helperText={options.errMsg.uploadCorrect}></WhiteTextField> */}

          <br />
          <br />
          <span className="instructions_heading">
            Instructions for Upload Format:
          </span>
          <br />
          <ul className="examiner-instructions">
            <li>
              Strictly see the sample.csv file below first and upload the files
              in the exact format
            </li>
            <li>
              Do not add any text/data above column headers please check the
              sample.csv file below and strictly adhere to the same format
            </li>
            <li>
              The First Row of the csv file should be column's Headers and from
              the second row all examiners data should be entered
              <br />
            </li>
            <li>
              Below are the Column Names that is needed in the csv
              file(Case-Sensitive & In Order), please make sure that the column
              names of the CSV files should be the same as below <br />
            </li>
            <table>
              <tbody>
                <tr>
                  <th>Course_Code</th>
                  <th>Course_Name</th>
                  {/* <th>gender</th> */}
                  <th>Category</th>
                  <th>Name</th>
                </tr>
                <tr>
                  <th>Designation</th>
                  <th>Organization</th>
                  <th>Mobile_No</th>
                  <th>Email_ID</th>
                </tr>
              </tbody>
            </table>
            <li>
              Strictly see <strong>Designation Column</strong> the entry for the
              same should be like{" "}
              <strong>"Designation - Department Name"</strong> i.e Do not create
              a seperate column for "Department Name"
            </li>
            <li>
              <strong>
                There should not be any empty row(s) in between the list of
                examiners
              </strong>
            </li>
            <li>
              <strong>
                Please make seperate files, if you want to bifurgate examiners
                on different parameters(eg. semester, year, etc)
              </strong>
            </li>
            <li>
              <strong>
                There should not be any bifurgation in between the entries of
                examiners (for eg. adding Semester in between the entries to
                bifurgate the examiners entry by semester).
              </strong>
            </li>
            <li>The only file type accepted is .csv (utf-8)</li>
            <li>Column Headers are required</li>
            {/* <li>Subjects must be separated by 'comma ( , )' in the SubjectData Field Eg. Physics,Maths,Chemistry</li> */}
            <li>All fields are required</li>
            <li>Any redundant data will be excluded from insertion</li>
            <li>
              You must strictly save your excel file as <b> CSV UTF-8</b> . Do
              not use any online convertors / other csv formats
            </li>
            <li>
              Sample File :{" "}
              <a
                href={sample}
                download="Sample - Panel of Examiners - B.Tech. (Information Technology).csv"
              >
                Sample.csv
              </a>
            </li>
            <li>
              Strictly use the above format only, any change would lead to
              failure in uploading the examiners
            </li>
          </ul>
        </div>
      </div>
    </form>
  );
}
