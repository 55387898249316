import React, { useState, useEffect } from 'react'
import * as classes from './Login.module.css'
import { Link } from 'react-router-dom'
import { Button } from 'react-bootstrap'
// import { TransitionInput } from '../utility/FormTransition'
import { Loading } from './../Loading';
import somaiyaLogo from '../../Assets/images/footerlogo1.jpg'

function Login(props) {

  const [state, setState] = useState({ email: '', password: '', err: props.Auth.err })
  // useEffect(() => {
  //   const input = document.querySelectorAll('input')
  //   TransitionInput(input)
  // }, [])

  function emailChangeHandler(e) {
    setState(prevState => ({
      ...prevState,
      email: e.target.value

    }))
  }
  function passwordChangeHandler(e) {
    setState(prevState => ({
      ...prevState,
      password: e.target.value
    }))
  }

  async function onSubmitHandler(e) {
    e.preventDefault()
    const creds = { email: state.email, pass: state.password }
    props.LoginUser(creds)
  }

  if (props.Auth.isLoading) {
    return (
      <Loading />
    );
  }
  else {
    const { email, password, err } = state //SAVE THE ERROR MESSAGE IN STATE FROM PROPS IF IT EXISTS AND USE IT
    return (
      <div className={classes.Login}>
        <div className={classes.LoginForm}>
          <img className={classes.LoginLogo} src={somaiyaLogo} alt="somaiya-logo" />
          <h4>Sign In To Your Account</h4>
          <form className={classes.FormSection} onSubmit={onSubmitHandler}>
            <div className={classes.email}>
              <label htmlFor="email">Email</label>
              <input className={`${classes.LoginInput} ${(err && err.email) ? classes.invalid : ''} `} type="email" name="email" onChange={emailChangeHandler} value={email} required />
              {(err && err.email) && <div className={classes.validationMsg}>{err.email}</div>}

            </div>
            <div className={classes.password}>
              <label>Password</label>
              <input className={`${classes.LoginInput} ${(err && err.password) ? classes.invalid : ''} `} type="password" name="password" onChange={passwordChangeHandler} value={password} required />
              {(err && err.password) && <div className={classes.validationMsg}>{err.password}</div>}


            </div>
            <div className={classes.btnCont}>
              <Button className={classes.LoginBtn} type="submit">Login</Button>
            </div>
            <div className={classes.Forget}>
              Set/ Reset Password?
              <Link style={{ color: "#911717", textDecoration: "none", fontWeight: "500" }} to="/forgotPassword"> Click Here</Link>
            </div>
          </form>
        </div>
      </div >
    )
  }
}


export default Login
