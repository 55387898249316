import React, { Component } from 'react'
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog'
import axios from 'axios'

import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Backdrop from '../Backdrop/Backdrop';
import InputBase from '@material-ui/core/InputBase';
import IconButton from '@material-ui/core/IconButton';
import SearchIcon from '@material-ui/icons/Search';
import TablePagination from '@material-ui/core/TablePagination';
import {InputLabel,Select,MenuItem} from "@material-ui/core";
import { DialogContent, DialogTitle, Typography, DialogActions } from '@material-ui/core';
import { Loading } from './../Loading';

import { baseUrl } from '../../redux/baseUrl';

// import './addSubject.css'

const root = {
  padding: '2px 4px',
  display: 'flex',
  alignItems: 'center',
  width: "",
  flexGrow: 1,
  backgroundColor: "#fafafa",
  boxShadow: "4px 2px 16px 2px rgba(0,0,0,.1)",
  border: "1px solid rgba(0,0,0,.1)",
  marginTop: "16px",
}

const input = {
  flex: 1,
}
const iconButton = {
  padding: 10,
}
const divider = {
  height: 28,
  margin: 4,
}


const StyledTableCell = withStyles((theme) => ({
  head: {
    backgroundColor: "#a02929",
    color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
    paddingLeft:50,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};


class addDepartment extends Component {

  constructor(props) {
    super(props)

    this.state = {
      dept: '',
      showModal: false,
      filteredDepts: [],
      deptArr:[],
      page: 0,
      rowsPerPage: 10,
      showPagination: true,
      isLoading: true,
      deleteId:"",
      deleteModal:false,
      isBtnDisabled:true,
      degree:"B.Tech"
    }

  }

  componentDidMount() {
    this.getdeptArr()
    this.getDepts()

  }


  getdeptArr = () => {
    axios.get(baseUrl+'/departments',{withCredentials:true})
      .then(res => {
        if (res.data.length === 0) {
          this.setState({ deptArr: ["No Entries"] })
          this.setState({ filteredDepts: ["No Entries"] })
          this.setState({ isLoading: false })
        } else {

          this.setState({ deptArr: [...res.data] })
          this.setState({ filteredDepts: [...res.data].slice(0, this.state.rowsPerPage) })
          this.setState({ isLoading: false })
        }
      })
      .catch(err => {
        console.log(err)
      })
  }

  getDepts = () => {
    axios.get(baseUrl+'/departments',{withCredentials:true})
      .then(res => {
        this.setState({ depts: [...res.data] })
      })
      .catch(err => {
        console.log(err)
      })
  }

  searchBarHandler = (e) => {
    const keyString = e.target.value.toLowerCase().trim()
    const res = []
    if (e.target.value === '') {
      this.setState({ showPagination: true })
    } else {
      this.setState({ showPagination: false })
    }
    this.state.deptArr.forEach(sub => {

      if (sub.Dept_Name.toLowerCase().includes(keyString) ) {
        res.push(sub)
      }
    })

    if (res.length === 0) {
      res.push("No Entries")
    }
    let temp = []
    if (res.length > 10) {
      temp = res.slice(0, this.state.rowsPerPage)
    } else {
      temp = [...res]
    }
    this.setState({ filteredDepts: [...temp] })
  }

  inputChangeHandler = (e) => {
    if(e.target.name === 'confirmDelete'){
      if(e.target.value === 'Delete-Department'){
        this.setState({
          isBtnDisabled:false
        })
      }else{
        this.setState({
          isBtnDisabled:true
        })
      }
    }
    else{
      this.setState({ [e.target.name]: e.target.value })
    }
  }

  deleteDepartment = () =>{
    axios.post(baseUrl+'/departments/delete/', { dept: this.state.deleteId },{withCredentials:true})
      .then(res => {

        document.querySelector("#searchbar").value = ""
        let new_arr = this.state.deptArr.filter(dept => dept._id != this.state.deleteId)
        this.setState({ 
            filteredDepts: new_arr,
            deptArr:new_arr,
            deletedId:"",
            deleteModal:false,
            isBtnDisabled:true
          })
        alert("Department Successfully Deleted")
      })
      .catch(err => {
        console.log(err)
        alert("There was some problem deleting the department. Please refresh and try again...")
      })
  }

  submitHandler = (e) => {
    e.preventDefault()

    let data
    
    data = {
    Dept_Name: this.state.dept,
    degree:this.state.degree
    }

    axios.post(baseUrl+'/departments', data,{withCredentials:true})
      .then(res => {
        let arr = [...this.state.deptArr,res.data]
        this.setState({
          dept:'',
          showModal: false,
          deptArr:arr,
          filteredDepts:arr.slice(0, this.state.rowsPerPage)
        })
        
        alert("Department(s) Added Successfully")

      })
      .catch(err => {
        console.log(err)
        
      })

  }

  ToggleModalHandler = (e) => {
    this.setState(prevState => { return { showModal: !prevState.showModal } })
  }

  closeModalHandler = () => {
    this.setState({ showModal: false })
  }

  deleteUserHandler = (e,id,name) => {

    if (window.confirm(`Are you sure you want to delete this Department (${name}) ? This will delete all the Subjects and Examiners within this department. Proceed with Caution!`)) {
      this.setState({
        deleteModal:true,
        deleteId:id
      })
    }
    
  }

  departmentChangeHandler = (e) => {
    this.setState({ department: e.target.value })
  }

  semesterChangeHandler = (e) => {
    this.setState({ semester: e.target.value })
  }

  handleChangePage = (e, newpage) => {

    this.setState({ page: newpage })
    const f = (newpage) * this.state.rowsPerPage
    const l = f + this.state.rowsPerPage
    const t = this.state.deptArr.slice(f, f + this.state.rowsPerPage)

    this.setState({ filteredDepts: t })
  }

  handleChangeRowsPerPage = (e) => {
    this.setState({ rowsPerPage: +(e.target.value) })
    const temp = this.state.deptArr.slice(0, e.target.value)
    this.setState({ filteredDepts: [...temp] })
    this.setState({ page: 0 })
  }

  handleClose = () =>{
    this.setState({
      deleteModal:false
    })
  }

  render() {
    if (this.state.isLoading) {
      return <Loading />
    }

    return (
      <div className="main-container" style={{ margin: '10px auto' }}>
        <Typography align="center" style={{ fontStyle: "Courier,Arial,sans-shreiff,TimesNewRoman", marginTop: '20px', color: "#a02929", textTransform: 'uppercase' }} color="textSecondary" variant="h4">
          Manage Departments</Typography>

        <div style={{ width: '95%', margin: '0 auto' }}>

          <Button variant="contained" onClick={this.ToggleModalHandler} style={{ marginTop: '10px', marginLeft: 'auto', display: 'block', color: "#a02929", backgroundColor: "#fff", border: "0.2px solid #a02929" }}>Add new Department</Button>
          <Paper style={root}>

            <InputBase
              style={input}
              id="searchbar"
              placeholder="Search By Department Name"
              inputProps={{ 'aria-label': 'Search By Department Name' }}
              onChange={this.searchBarHandler}
            />
            <IconButton style={iconButton} aria-label="search">
              <SearchIcon />
            </IconButton>
          </Paper>
        </div>


        <TableContainer component={Paper} style={{ width: '95%', margin: '15px auto' }}>
          <Table aria-label="customized table">
            <TableHead>
              <TableRow>
                {/* <StyledTableCell>Full Name</StyledTableCell> */}
                <StyledTableCell style={{paddingLeft:50}}>Department Name</StyledTableCell>
                <StyledTableCell style={{paddingLeft:50}}>Delete Department</StyledTableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {this.state.filteredDepts[0] === 'No Entries' ? (<StyledTableRow><StyledTableCell><div style={{ color: "red", fontWeight: "500" }}>No Department Found</div></StyledTableCell></StyledTableRow>) : this.state.filteredDepts.map((dept) => (
                <StyledTableRow key={dept._id} className="one-user">
                  <StyledTableCell>{dept.Dept_Name} </StyledTableCell>
                  <StyledTableCell><Button onClick={(e) => { this.deleteUserHandler(e, dept._id, dept.Dept_Name) }} variant="contained" style={{ backgroundColor: "#a02929", color: "#fff" }}>Delete Department</Button> </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>

        {this.state.showPagination ? <TablePagination
          component="div"
          count={this.state.deptArr.length}
          page={this.state.page}
          onChangePage={this.handleChangePage}
          rowsPerPage={this.state.rowsPerPage}
          onChangeRowsPerPage={this.handleChangeRowsPerPage}
        /> : null}



        <div className="form-box" style={{ transform: this.state.showModal ? `translateY(0) translateX(-50%)` : `translateY(-100vh) translateX(-50%)`, opacity: this.state.showModal ? `1` : `0` }}>
          <div style={{ position: 'relative', top: '0', left: '0', width: '100%', textAlign: 'center', backgroundColor: '#911717', color: '#fff', padding: '10px', fontSize: '24px', marginBottom: '10px' }}>
            Add Department(s)
          </div>
          <form onSubmit={this.submitHandler} >
            <TextField required id="standard-required"  label="Department Name" name="dept" onChange={this.inputChangeHandler} value={this.state.dept} />
            <br />
            <InputLabel id="degree-label">Graduation Level</InputLabel>
            <Select labelId="degree-label" value={this.state.degree} onChange={(e) => this.setState({degree:e.target.value})} MenuProps={MenuProps}>
              <MenuItem  key={"B.Tech"} value="B.Tech">
                B.Tech
              </MenuItem>
              <MenuItem key={"M.Tech"} value="M.Tech">
                M.Tech
              </MenuItem>
            </Select>

            <br />
            <br />
            <center><Button variant="contained" style={{ color: "#a02929", width: "40%", backgroundColor: "#fff", border: "0.2px solid #a02929", margin: '10 0 10 10', marginBottom: '0' }} type="submit">Add Department</Button></center>
          </form>

        </div>
        
        
        
          <Dialog 
            open = {this.state.deleteModal}
            aria-labelledby="alert-dialog-title"
            onClose={this.handleClose}
          >
            <DialogTitle>Enter the following text to delete: <span style={{color:"red"}}>Delete-Department</span></DialogTitle>
            <DialogContent><TextField style={{width:"100%"}} value={this.state.confirmDelete} name="confirmDelete" onChange={this.inputChangeHandler}></TextField></DialogContent>
            <DialogActions style={{alignSelf:"center"}}><center>{!this.state.isBtnDisabled?<Button onClick={this.deleteDepartment} style={{ marginTop: '10px', display: 'block', color: "#a02929", backgroundColor: "#fff", border: "0.2px solid #a02929" }}>Delete Department</Button>:null}</center></DialogActions>
          </Dialog>

        <Backdrop show={this.state.showModal} clicked={this.closeModalHandler} />
      </div>

      
    )
  }
}

export default addDepartment
