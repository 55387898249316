import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import IconButton from "@material-ui/core/IconButton";
import InputBase from "@material-ui/core/InputBase";
import Paper from "@material-ui/core/Paper";
import { lighten, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Toolbar from "@material-ui/core/Toolbar";
import Tooltip from "@material-ui/core/Tooltip";
import Typography from "@material-ui/core/Typography";
import {
  Delete,
  Edit,
  ErrorOutlineOutlined,
  LibraryAdd,
  Save,
  Search,
} from "@material-ui/icons";
import axios from "axios";
import clsx from "clsx";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { Loading } from "../Loading";
import AddExaminers from "./AddExaminers";
import EditExaminers from "./EditExaminers";
import CircularProgress from "@material-ui/core/CircularProgress";

import { baseUrl } from "../../redux/baseUrl";

function createData(faculty_id, faculty, type, course_id, email) {
  return { faculty_id, faculty, type, course_id, email };
}

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  { id: "faculty", numeric: false, disablePadding: false, label: "Faculty" },
  {
    id: "type",
    numeric: false,
    disablePadding: false,
    label: "Examiner / Paper Setter Type",
  },
  {
    id: "course_id",
    numeric: false,
    disablePadding: false,
    label: "Department",
  },
  { id: "edit", numeric: false, disablePadding: false, label: "" },
];

function EnhancedTableHead(props) {
  const {
    classes,
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props; //onSelectAllClick removed
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          {props.showCheckBox ? (
            <Checkbox
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              inputProps={{ "aria-label": "select all desserts" }}
              classes={{ checked: classes.checkColor }}
            />
          ) : (
            <div></div>
          )}
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? "right" : "left"}
            padding={headCell.disablePadding ? "none" : "default"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight: {
    color: theme.palette.success.dark,
    backgroundColor: lighten(theme.palette.success.light, 0.85),
  },
  wrapIcon: {
    verticalAlign: "middle",
    display: "inline-flex",
  },
  title: {
    flex: "1 1 100%",
    fontSize: "18px",
  },
  highlightWarn: {
    color: theme.palette.warning.dark,
    backgroundColor: lighten(theme.palette.warning.light, 0.85),
  },
  delete: {
    color: theme.palette.error.dark,
    backgroundColor: lighten(theme.palette.error.light, 0.85),
  },
}));

const EnhancedTableToolbar = (props) => {
  let options = props.enhancedTableToolBarOptions;
  function handleSubmit() {
    let selected = props.selected;
    if (window.confirm("Are you sure you want to delete selected examiners?")) {
      const axios_opts = {
        url: baseUrl + "/examiner/delete",
        method: "DELETE",
        data: { examiners: selected },
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json;charset=UTF-8",
        },
        withCredentials: true,
      };
      axios(axios_opts)
        .then((res) => {
          alert("Examiner(s) Removed Successfully");
          // options.setFlash("Examiner(s) Removed Successfully")
          // setTimeout(() => {
          //   options.setFlash("")
          // }, 5000)
          options.setSelected([]);
          var tempArr = options.rows.filter(
            (row) => !selected.includes(row.faculty_id)
          );
          if (tempArr.length === 0) {
            tempArr.push("No Examiners Found");
          }
          options.setRows([...tempArr]);
        })
        .catch((err) => console.log(err));
    }
  }
  const classes = useToolbarStyles();
  const { numSelected } = props;
  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlightWarn]: numSelected == 0 || numSelected > 0,
        [classes.delete]: numSelected > 0,
      })}
    >
      {numSelected == 0 ? (
        <Typography
          className={classes.title}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          <small>
            <ErrorOutlineOutlined
              className={classes.wrapIcon}
              fontSize="small"
            />
          </small>{" "}
          &nbsp; CAUTION : Not Enough Examiners Selected
        </Typography>
      ) : (
        <Typography
          className={classes.title}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} Examiners Selected
        </Typography>
      )}

      {numSelected > 0 ? (
        <Tooltip title="Delete">
          <Button
            onClick={() => handleSubmit()}
            color="default"
            className={(classes.button, classes.delete)}
            endIcon={<Delete />}
          >
            Delete
          </Button>
        </Tooltip>
      ) : (
        <div></div>
      )}
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    padding: "50px",
    paddingTop: "0px",
  },
  paper: {
    width: "100%",
    marginBottom: theme.spacing(2),
    padding: "20px",
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: "rect(0 0 0 0)",
    height: 1,
    margin: -1,
    overflow: "hidden",
    padding: 0,
    position: "absolute",
    top: 20,
    width: 1,
  },
  highlight_row: {
    backgroundColor: lighten("#a02929", 0.85),
  },
  none: {
    backgroundColor: "#fff!important",
  },
  checkColor: {
    color: "#a02929 !important",
  },
  addExaminers: {
    color: "#fff",
    backgroundColor: "#a02929",
    marginLeft: "auto",
    borderColor: "#a02929",
    "&:hover": {
      backgroundColor: lighten("#a02929", 0.15),
    },
  },
  editExaminersButton: {
    color: "#a02929",
    borderColor: "#a02929",
    "&:hover": {
      borderColor: "#a02929",
    },
  },
  header: {
    fontStyle: "Courier,Arial,sans-shreiff,TimesNewRoman",
    textTransform: "uppercase",
    color: "#a02929",
  },
  flashMessage: {
    color: theme.palette.success.dark,
    backgroundColor: lighten(theme.palette.success.light, 0.85),
    fontSize: "22px",
    width: "100%",
    textAlign: "center",
  },
  dialog: {
    minWidth: 1500,
  },
  spinner: {
    color: "#fff!important",
    float: "right",
  },
}));

export default function Examiners() {
  const classes = useStyles();
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("subject");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [rows, setRows] = React.useState([]);
  // const [flash, setFlash] = React.useState("")
  const [openAddExaminers, setOpenAddExaminers] = React.useState(false);
  const [isLoading, setIsLoading] = useState(false);
  //Add Examiners
  // const deptArr = ["Information Technology","Computer Science","Electonics and Telecommunication","Electronics"]
  const [examinerType, setExaminerType] = useState("Internal");
  const [dept, setDept] = useState();
  const [info, setInfo] = useState({});
  const [allSubjects, setAllSubjects] = useState([]);
  const [filteredSubjects, setFilteredSubjects] = useState([]);
  const [examinerAddedFlash, setExaminerAddedFlash] = useState("");
  const [disabled, setDisabled] = useState(false);
  const [errMsg, setErrMsg] = useState({
    allFieldsFilled: "",
    emailCorrect: "",
    contactCorrect: "",
    UploadCorrect: "",
  });
  const [searchRows, setSearchRows] = useState([]);
  const [deptArr, setDeptArr] = useState([]);

  //Edit Examiners - inherit add examiners + ...
  const [edit, setEdit] = useState(false);
  const [faculty_id, setFaculty_id] = useState();
  const [fetch_from_db, setFetch_from_db] = useState(false);

  var AddExaminerOptions = {
    deptArr: deptArr,
    examinerType: examinerType,
    dept: dept,
    info: info,
    disabled: disabled,
    errMsg: errMsg,
    examinerAddedFlash: examinerAddedFlash,
    filteredSubjects: filteredSubjects,

    setExaminerType: setExaminerType,
    setDept: setDept,
    setInfo: setInfo,
    setDisabled: setDisabled,
    setErrMsg: setErrMsg,
  };
  const clearAddExaminerOptions = () => {
    // setFilteredSubjects([]);
    setExaminerType("Internal");
    setDept(deptArr[0].Dept_Name);
    setInfo({});
    setDisabled(false);
    setErrMsg({ allFieldsFilled: "", emailCorrect: "", contactCorrect: "" });
  };

  const searchHandler = (e) => {
    if (rows) {
      const keyString = e.target.value.toLowerCase().trim();
      if (keyString) {
        let res = [];
        searchRows.forEach((row) => {
          if (
            row.faculty.toLowerCase().includes(keyString) ||
            row.course_id.toLowerCase().includes(keyString) ||
            row.email.toLowerCase().includes(keyString)
          ) {
            res.push(row);
          }
        });
        if (res.length === 0) {
          res.push("No Examiners Found");
        }
        setRows(res);
      } else {
        setRows(searchRows);
      }
    }
  };

  const handleAdd = () => {
    setEdit(false);
    handleClickOpen();
  };
  const handleClickOpen = () => {
    setOpenAddExaminers(true);
  };

  const handleEdit = (faculty_id) => {
    setEdit(true);
    setFaculty_id(faculty_id);
    axios
      .get(baseUrl + "/examiner/" + faculty_id, { withCredentials: true })
      .then((res) => {
        let data = res.data[0];
        let fn = data.Name.split(" ")[0];
        let ln = data.Name.split(" ")[1];
        setInfo({
          first_name: fn,
          last_name: ln,
          email: data.email,
          contact: data.contact,
          subjectData: data.subjectData,
          Organization: data.Organization,
          Designation: data.Designation,
        });
        setDept(data.department.Dept_Name);
        setExaminerType(data.type);
        handleClickOpen();
      })
      .catch((err) => console.log(err));
  };
  const handleClose = () => {
    setOpenAddExaminers(false);
    clearAddExaminerOptions();
  };

  useEffect(() => {
    axios.get(baseUrl + "/subjects", { withCredentials: true }).then((res) => {
      setAllSubjects(res.data);
      let temp_subjects = [];
      res.data.forEach((subject) => {
        temp_subjects.push(subject.name);
      });
      setFilteredSubjects(temp_subjects);
    });

    axios
      .get(baseUrl + "/departments", { withCredentials: true })
      .then((res) => {
        if (Object.keys(res.data).length != 0) {
          setDeptArr(res.data);
          setDept(res.data[0].Dept_Name);
        }
      });
  }, []);

  // useEffect(() => {
  //   let temp_subjects = [];
  //   allSubjects.forEach((subject) => {
  //     if (subject.department.Dept_Name == dept) {
  //       temp_subjects.push(subject.name);
  //     }
  //   });

  // }, [dept, allSubjects]);

  useEffect(() => {
    axios.get(baseUrl + "/examiner", { withCredentials: true }).then((res) => {
      let temp_rows = [];
      res.data.forEach((teacher) => {
        temp_rows.push(
          createData(
            teacher._id,
            teacher.Name,
            teacher.type,
            teacher.department.Dept_Name,
            teacher.email
          )
        );
      });
      setSelected([]);
      if (temp_rows.length === 0) {
        temp_rows.push("No Examiners Found");
      }
      setRows(temp_rows);
      setSearchRows(temp_rows);
    });
  }, [fetch_from_db]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.faculty_id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, faculty_id) => {
    const selectedIndex = selected.indexOf(faculty_id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, faculty_id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (faculty_id) => selected.indexOf(faculty_id) !== -1;

  const emptyRows =
    rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);
  var temp = Array.from(Array(rows.length).keys());
  var RowOptions = temp.filter((n) => n % 5 == 0);
  var enhancedTableToolBarOptions = {
    // setFlash: setFlash,
    setSelected: setSelected,
    setFetch_from_db: setFetch_from_db,
    setRows: setRows,

    rows: rows,
  };
  function handleSubmitEditExaminer() {
    const re_email =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (!(info.first_name && info.last_name && info.email && info.contact)) {
      setErrMsg({
        ...errMsg,
        allFieldsFilled: "Fill all the required fields before saving",
      });
      return;
    }
    if (!re_email.test(String(info.email.toLowerCase()))) {
      setErrMsg({ ...errMsg, emailCorrect: "Enter an appropriate email" });
      return;
    }
    if (String(info.contact).match(/\d/g).length != 10) {
      setErrMsg({
        ...errMsg,
        contactCorrect: "Enter a 10-digit mobile number",
      });
      return;
    }

    let data = {
      updatedExaminer: {
        ...info,
        type: examinerType,
        Name: `${info.first_name} ${info.last_name}`,
      },
    };
    delete data["first_name"];
    delete data["last_name"];
    deptArr.forEach((d) => {
      if (d.Dept_Name == dept) {
        data.updatedExaminer.department = d._id;
        return;
      }
    });
    let subs = [];
    allSubjects.forEach((subject) => {
      if (info.subjectData.includes(subject.name)) {
        subs.push(subject._id);
      }
    });
    data.updatedExaminer.subjectData = subs;

    let options = {
      url: baseUrl + "/examiner/edit/" + faculty_id,
      method: "PUT",
      data: data,
      withCredentials: true,
    };

    axios(options)
      .then((res) => {
        window.scrollTo(0, 0);
        setEdit(false);
        setOpenAddExaminers(false);
        setSelected([]);
        let data = res.data;
        let row_copy = [...searchRows];
        searchRows.map((row, index) => {
          if (row.faculty_id == data.id) {
            row_copy[index] = createData(
              data.id,
              data.body.Name,
              data.body.type,
              dept,
              data.body.email
            );
          }
        });
        setRows(row_copy);
        setSearchRows(row_copy);
        // setFetch_from_db(!fetch_from_db)
        alert("Edited Examiner Successfully");

        // setTimeout(() => {
        //   setExaminerAddedFlash("")
        // }, 5000)
      })
      .catch((err) => console.log(err));
  }

  function handleSubmitAddExaminer(e) {
    e.preventDefault();
    const re_email =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (!info.file) {
      if (
        !(
          info.first_name &&
          info.last_name &&
          info.email &&
          info.contact &&
          info.subjectData.length > 0
        )
      ) {
        setErrMsg({
          ...errMsg,
          allFieldsFilled: "Fill all the required fields before saving",
        });
        return;
      }
      if (!re_email.test(String(info.email.toLowerCase()))) {
        setErrMsg({ ...errMsg, emailCorrect: "Enter an appropriate email" });
        return;
      }
      if (String(info.contact).match(/\d/g).length != 10) {
        setErrMsg({
          ...errMsg,
          contactCorrect: "Enter a 10-digit mobile number",
        });
        return;
      }
    }
    setIsLoading(true);
    let data;
    let url = "";
    if (info.file) {
      const formData = new FormData();
      formData.set("examiner_file", info.file[0]);
      data = formData;
      url = "/examiner/upload";
    } else {
      data = {
        newExaminer: {
          ...info,
          department: dept,
          type: examinerType,
          Name: `${info.first_name} ${info.last_name}`,
        },
      };
      let subs = [];
      allSubjects.forEach((subject) => {
        if (info.subjectData.includes(subject.name)) {
          subs.push(subject._id);
        }
      });
      data.newExaminer.subjectData = subs;

      delete data.newExaminer.first_name;
      delete data.newExaminer.last_name;

      // delete data.newExaminer['file']
      url = "/examiner";
    }
    let options = {
      url: baseUrl + url,
      method: "POST",
      data,
      withCredentials: true,
    };

    axios(options)
      .then((res) => {
        setIsLoading(false);

        window.scrollTo(0, 0);
        if (url === "/examiner") {
          let data = res.data;
          let new_row = createData(
            data._doc._id,
            data._doc.Name,
            data._doc.type,
            data.Dept_Name,
            data.email
          );
          setSearchRows([...rows, new_row]);
          setRows([...rows, new_row]);
        } else {
          setFetch_from_db(!fetch_from_db);
        }

        alert("Added Examiner(s) Successfully");
        // setExaminerAddedFlash("Added Examiner Successfully")
        // setTimeout(() => {
        //   setExaminerAddedFlash("")
        // }, 5000)

        clearAddExaminerOptions();
      })
      .catch((err) => {
        setIsLoading(false);
        if (url === "/examiner/upload" && typeof err.response != "undefined") {
          console.log(err.response);
          setErrMsg({ ...errMsg, uploadCorrect: err.response.data });
          setDisabled(false);
          setInfo({ ...info, file: "" });
          setFetch_from_db(!fetch_from_db);
        } else {
          alert(
            "Something went wrong, Please Try Again with a proper format.."
          );
        }
      });
  }
  if (rows.length > 0 || dept) {
    return (
      <div className={classes.root}>
        {/* <div className={classes.flashMessage}>{flash}</div> */}
        <Paper className={classes.paper}>
          <Typography
            align="center"
            className={classes.header}
            color="textSecondary"
            variant="h4"
          >
            Manage Examiners / Paper Setters
          </Typography>
          <div className="search-bar">
            <div className="search-input">
              <InputBase
                className={"input"}
                placeholder="Search By Examiner's name or Department"
                inputProps={{ "aria-label": "Search By Examiner's name" }}
                onChange={searchHandler}
              />
              <IconButton aria-label="search">
                <Search />
              </IconButton>
            </div>

            <Button
              variant="outlined"
              onClick={handleAdd}
              className={classes.addExaminers}
            >
              Add Examiners&nbsp;
              <LibraryAdd />
            </Button>
          </div>
          <Dialog
            open={openAddExaminers}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            // maxWidth = "200px"
          >
            {/* <div className={classes.flashMessage}>{examinerAddedFlash}</div> */}
            <DialogTitle
              style={{ backgroundColor: "#a02929", color: "#fff" }}
              align="center"
              id="alert-dialog-title"
            >
              {!edit ? "Add / Upload Examiners" : `Edit Examiner`}
              {isLoading ? (
                <CircularProgress size={28} className={classes.spinner} />
              ) : null}
            </DialogTitle>
            <DialogContent>
              {!edit ? (
                <AddExaminers options={AddExaminerOptions} />
              ) : (
                <EditExaminers options={AddExaminerOptions} />
              )}
            </DialogContent>
            <DialogActions className={classes.highlight_row}>
              <Button
                style={{
                  marginRight: "20px",
                  backgroundColor: "#a02929",
                  color: "#fff",
                  borderColor: "#fff",
                }}
                variant="outlined"
                onClick={
                  edit ? handleSubmitEditExaminer : handleSubmitAddExaminer
                }
                color="primary"
              >
                Save&nbsp;
                <Save />
              </Button>
            </DialogActions>
          </Dialog>
          <TableContainer>
            <Table
              className={classes.table}
              aria-labelledby="tableTitle"
              size={"medium"}
              aria-label="enhanced table"
            >
              <EnhancedTableHead
                classes={classes}
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={rows.length}
                showCheckBox={rows == searchRows}
              />
              <TableBody>
                {rows[0] != "No Examiners Found" ? (
                  stableSort(rows, getComparator(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      const isItemSelected = isSelected(row.faculty_id);
                      const labelId = `enhanced-table-checkbox-${index}`;
                      return (
                        <TableRow
                          hover
                          role="checkbox"
                          aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={row.faculty_id}
                          selected={isItemSelected}
                        >
                          <TableCell padding="checkbox">
                            {rows[0] != "No Examiners Found" ? (
                              <Checkbox
                                onClick={(event) =>
                                  handleClick(event, row.faculty_id)
                                }
                                checked={isItemSelected}
                                classes={{ checked: classes.checkColor }}
                                inputProps={{ "aria-labelledby": labelId }}
                              />
                            ) : (
                              <div></div>
                            )}
                          </TableCell>
                          <TableCell component="th" id={labelId} scope="row">
                            {row.faculty}
                          </TableCell>
                          <TableCell hidden align="left">
                            {row.faculty_id}
                          </TableCell>
                          <TableCell align="left">{row.type}</TableCell>
                          <TableCell align="left">{row.course_id}</TableCell>
                          <TableCell align="left">
                            <Button
                              className={classes.editExaminersButton}
                              variant="outlined"
                              color="primary"
                              onClick={() => handleEdit(row.faculty_id)}
                            >
                              EDIT &nbsp;
                              <Edit fontSize="small" />{" "}
                            </Button>
                          </TableCell>
                        </TableRow>
                      );
                    })
                ) : (
                  <TableRow>
                    <TableCell
                      colSpan="5"
                      style={{ textAlign: "center", color: "red" }}
                    >
                      NO EXAMINERS FOUND
                    </TableCell>
                  </TableRow>
                )}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={3} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <EnhancedTableToolbar
            enhancedTableToolBarOptions={enhancedTableToolBarOptions}
            selected={selected}
            numSelected={selected.length}
          />
          <TablePagination
            rowsPerPageOptions={RowOptions.slice(1)}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onChangePage={handleChangePage}
            onChangeRowsPerPage={handleChangeRowsPerPage}
          />
        </Paper>
      </div>
    );
  } else {
    return <Loading />;
  }
}
